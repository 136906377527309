import { DataTable } from '@tableau/embedding-api';
import axios from 'axios';
import OpenAI from 'openai';
import { Blob } from 'openai/_shims';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL_GPT,
});


/**
 * Analyze the image generated by the Sketch component with the provided user message.
 * @param {Object} userMessage - An object containing the `prompt` and `sketchRef`.
 * @param {string} userMessage.prompt - The prompt to analyze.
 * @param {React.RefObject<SketchRef>} userMessage.sketchRef - The reference to the Sketch component.
 * @returns {Promise<OpenAI.Chat.ChatCompletion>} The API response.
 */
export const analyzeImageWithPrompt = async (
    userPrompt: string,
    sketchBlob: Blob,
    attributes: any,
    tableauVizDataTable: DataTable
): Promise<OpenAI.Chat.ChatCompletion> => {
    const prompt = userPrompt + " The Y axis represents these attributes: " + JSON.stringify(attributes) + " The X axis represents time. Ignore other colors. " + 
        "This sketch was used to query Tableau which resulted in this Tableau Viz DataTable: " + JSON.stringify(tableauVizDataTable) + 
        "When describing the sketch, please do not encapsulate the DataTable as part of that response. The DataTable should only be used to describe the results from the sketch." + 
        "When describing the DataTable results, refer to it as 'Tableau Visualization'";

    const formData = new FormData();
    formData.append('image', sketchBlob, 'sketch.png'); // Dynamically generated image blob
    formData.append('prompt', prompt);

    console.log(formData);

    const response = await api.post('/process-image', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data.response;
};