import React, { useContext, useEffect, useRef, useState } from "react";
import { ChatTextType } from "./ChatText";
import { analyzeImageWithPrompt } from "./GPTApiService";

import { ReactComponent as EinsteinMessageLogo } from "../EinsteinMessage_Logo.svg";
import { ReactComponent as EinsteinUserMessage } from "../EinsteinUserMessage_Logo.svg";
import { ReactComponent as SendMessageIcon } from "../SendMessage_Icon.svg";

import "./Chat.css";
import OpenAI from "openai";
import { throwIfNull } from "./utils";
import { AppContext } from "./AppContext";

const Chat: React.FC = () => {
  const {sketchBlob, shapeFilterLibrary, currentShapeIndex, listOfMeasures, tableauVizDataTable} = throwIfNull(useContext(AppContext));

  const [messageList, setMessageList] = useState<ChatTextType[]>([]);

  const scrollItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // if last message is from a user, call gpt
    if (messageList.length > 0) {
      const lastMessage = messageList[messageList.length - 1];
      if (lastMessage && lastMessage.isUserLogo) {
        callGPT(lastMessage.text);
      }
    }
    
    // scroll to bottom of message list
    if (scrollItemRef.current) {
      scrollItemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [messageList]);

  let createChatMessage = (item: ChatTextType): JSX.Element => (
    <div
      className={`message-item einstein-message  ${
        item.includeLogo ? "" : "message-item-compact"
      }`}
      key={item.text.split(" ")[0]}
    >
      <div className={`message-logo ${item.includeLogo ? "" : "hidden-logo"}`}>
        {item.isUserLogo ? <EinsteinUserMessage /> : <EinsteinMessageLogo />}
      </div>
      <p>{item.text}</p>
    </div>
  );

  const callGPT = async (userMessage: string) => {
    if (sketchBlob && tableauVizDataTable) {
      const currentShape = shapeFilterLibrary[currentShapeIndex];
      let usedMeasures: {measureName: string, color: string}[] = [];
      listOfMeasures.forEach(measure => {
        if (currentShape.measureShapes.has(measure.measureName)) {
          usedMeasures.push({measureName: measure.measureName, color: measure.color});
        }
      });
      const attributes = {
        usedMeasures,
      };
      console.log(attributes);
      analyzeImageWithPrompt(userMessage, sketchBlob, attributes, tableauVizDataTable)
        .then((response: OpenAI.Chat.ChatCompletion) => {
          response.choices.forEach((choice: any) => {
            if (choice.message.content) {
              const messageType: ChatTextType = {
                text: choice.message.content,
                includeLogo: true,
                isUserLogo: false,
              };
              setMessageList((prev) => [...prev, messageType]); // Update messageList
            }
          });
        })
        .catch((error) => {
          console.error('Error analyzing image:', error);
        });
      } else {
        console.error('no image blob to send to gpt');
      }
  };


  const [messageText, setMessageText] = useState("");
  const chatBox: JSX.Element = (
    <div className="message-input-wrapper">
      <div
        className="message-input-text-wrapper"
        id="message-input-text-wrapper"
      >
        <textarea
          maxLength={1000}
          placeholder="Describe your task or ask a question..."
          className="message-input-text-box"
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
        ></textarea>
        <div
          aria-live="off"
          className="message"
          id="tableau-ui-1731012068934"
        ></div>
      </div>
      <button
        className="send-button"
        onClick={() => {
          setMessageList([
            ...messageList,
            { text: messageText, includeLogo: true, isUserLogo: true },
          ]);
          setMessageText("");
        }}
      >
        <SendMessageIcon />
      </button>
    </div>
  );

  const chatPane: JSX.Element = (
    <div className="message-area">
      <div className="message-list-blank-space"></div>
      <div className="message-list-wrapper">
        <div className="message-list">
          {messageList.map((message) => createChatMessage(message))}
          <div ref={scrollItemRef}></div>
        </div>
      </div>
      {chatBox}
    </div>
  );

  return (
    <div className="tableau-agent">
      {chatPane}
    </div>
  );
};

export default Chat;
