export const sampleAnnotationSegments = [
    {
        "xStart": -0.08095238095238096,
        "yStart": -0.02976190476190476,
        "xEnd": -0.08095238095238096,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": -0.08095238095238096,
        "yStart": -0.02976190476190476,
        "xEnd": -0.08095238095238096,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": -0.08095238095238096,
        "yStart": -0.02976190476190476,
        "xEnd": -0.08095238095238096,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": -0.08095238095238096,
        "yStart": -0.02976190476190476,
        "xEnd": -0.07936507936507936,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": -0.07936507936507936,
        "yStart": -0.02976190476190476,
        "xEnd": -0.07936507936507936,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": -0.07936507936507936,
        "yStart": -0.02976190476190476,
        "xEnd": -0.07936507936507936,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": -0.07936507936507936,
        "yStart": -0.02976190476190476,
        "xEnd": -0.07777777777777778,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": -0.07777777777777778,
        "yStart": -0.02976190476190476,
        "xEnd": -0.0761904761904762,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": -0.0761904761904762,
        "yStart": -0.02976190476190476,
        "xEnd": -0.0746031746031746,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": -0.0746031746031746,
        "yStart": -0.02976190476190476,
        "xEnd": -0.07142857142857142,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": -0.07142857142857142,
        "yStart": -0.02976190476190476,
        "xEnd": -0.06825396825396825,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": -0.06825396825396825,
        "yStart": -0.02976190476190476,
        "xEnd": -0.06666666666666667,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": -0.06666666666666667,
        "yStart": -0.02976190476190476,
        "xEnd": -0.023809523809523808,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": -0.023809523809523808,
        "yStart": -0.02976190476190476,
        "xEnd": -0.014285714285714285,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": -0.014285714285714285,
        "yStart": -0.02976190476190476,
        "xEnd": 0.0015873015873015873,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.0015873015873015873,
        "yStart": -0.02976190476190476,
        "xEnd": 0.007936507936507936,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.007936507936507936,
        "yStart": -0.02976190476190476,
        "xEnd": 0.01904761904761905,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.01904761904761905,
        "yStart": -0.02976190476190476,
        "xEnd": 0.025396825396825397,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.025396825396825397,
        "yStart": -0.02976190476190476,
        "xEnd": 0.031746031746031744,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.031746031746031744,
        "yStart": -0.02976190476190476,
        "xEnd": 0.03968253968253968,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.03968253968253968,
        "yStart": -0.02976190476190476,
        "xEnd": 0.047619047619047616,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.047619047619047616,
        "yStart": -0.02976190476190476,
        "xEnd": 0.05396825396825397,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.05396825396825397,
        "yStart": -0.02976190476190476,
        "xEnd": 0.06349206349206349,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.06349206349206349,
        "yStart": -0.02976190476190476,
        "xEnd": 0.06984126984126984,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.06984126984126984,
        "yStart": -0.02976190476190476,
        "xEnd": 0.0761904761904762,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.0761904761904762,
        "yStart": -0.02976190476190476,
        "xEnd": 0.08253968253968254,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.08253968253968254,
        "yStart": -0.02976190476190476,
        "xEnd": 0.09047619047619047,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.09047619047619047,
        "yStart": -0.02976190476190476,
        "xEnd": 0.09206349206349207,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.09206349206349207,
        "yStart": -0.02976190476190476,
        "xEnd": 0.09841269841269841,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.09841269841269841,
        "yStart": -0.02976190476190476,
        "xEnd": 0.10634920634920635,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.10634920634920635,
        "yStart": -0.02976190476190476,
        "xEnd": 0.1111111111111111,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.1111111111111111,
        "yStart": -0.02976190476190476,
        "xEnd": 0.11587301587301588,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.11587301587301588,
        "yStart": -0.026785714285714284,
        "xEnd": 0.12222222222222222,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.12222222222222222,
        "yStart": -0.026785714285714284,
        "xEnd": 0.12698412698412698,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.12698412698412698,
        "yStart": -0.026785714285714284,
        "xEnd": 0.13174603174603175,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.13174603174603175,
        "yStart": -0.026785714285714284,
        "xEnd": 0.1349206349206349,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.1349206349206349,
        "yStart": -0.026785714285714284,
        "xEnd": 0.14761904761904762,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.14761904761904762,
        "yStart": -0.026785714285714284,
        "xEnd": 0.15396825396825398,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.15396825396825398,
        "yStart": -0.026785714285714284,
        "xEnd": 0.15714285714285714,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.15714285714285714,
        "yStart": -0.026785714285714284,
        "xEnd": 0.1619047619047619,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.1619047619047619,
        "yStart": -0.026785714285714284,
        "xEnd": 0.16825396825396827,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.16825396825396827,
        "yStart": -0.023809523809523808,
        "xEnd": 0.1746031746031746,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.1746031746031746,
        "yStart": -0.023809523809523808,
        "xEnd": 0.20317460317460317,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.20317460317460317,
        "yStart": -0.023809523809523808,
        "xEnd": 0.20634920634920634,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.20634920634920634,
        "yStart": -0.023809523809523808,
        "xEnd": 0.20952380952380953,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.20952380952380953,
        "yStart": -0.023809523809523808,
        "xEnd": 0.2126984126984127,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.2126984126984127,
        "yStart": -0.023809523809523808,
        "xEnd": 0.21746031746031746,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.21746031746031746,
        "yStart": -0.023809523809523808,
        "xEnd": 0.22063492063492063,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.22063492063492063,
        "yStart": -0.023809523809523808,
        "xEnd": 0.2253968253968254,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.2253968253968254,
        "yStart": -0.023809523809523808,
        "xEnd": 0.23015873015873015,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.23015873015873015,
        "yStart": -0.023809523809523808,
        "xEnd": 0.23174603174603176,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.23174603174603176,
        "yStart": -0.023809523809523808,
        "xEnd": 0.2396825396825397,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.2396825396825397,
        "yStart": -0.023809523809523808,
        "xEnd": 0.2396825396825397,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.2396825396825397,
        "yStart": -0.023809523809523808,
        "xEnd": 0.24444444444444444,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.24444444444444444,
        "yStart": -0.023809523809523808,
        "xEnd": 0.2492063492063492,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.2492063492063492,
        "yStart": -0.023809523809523808,
        "xEnd": 0.25396825396825395,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.25396825396825395,
        "yStart": -0.023809523809523808,
        "xEnd": 0.25555555555555554,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.25555555555555554,
        "yStart": -0.023809523809523808,
        "xEnd": 0.25873015873015875,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.25873015873015875,
        "yStart": -0.023809523809523808,
        "xEnd": 0.2634920634920635,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.2634920634920635,
        "yStart": -0.023809523809523808,
        "xEnd": 0.26666666666666666,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.26666666666666666,
        "yStart": -0.023809523809523808,
        "xEnd": 0.26825396825396824,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.26825396825396824,
        "yStart": -0.023809523809523808,
        "xEnd": 0.273015873015873,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.273015873015873,
        "yStart": -0.023809523809523808,
        "xEnd": 0.2777777777777778,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.2777777777777778,
        "yStart": -0.023809523809523808,
        "xEnd": 0.28253968253968254,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.28253968253968254,
        "yStart": -0.023809523809523808,
        "xEnd": 0.2873015873015873,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.2873015873015873,
        "yStart": -0.023809523809523808,
        "xEnd": 0.28888888888888886,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.28888888888888886,
        "yStart": -0.023809523809523808,
        "xEnd": 0.29523809523809524,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.29523809523809524,
        "yStart": -0.023809523809523808,
        "xEnd": 0.3,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.3,
        "yStart": -0.023809523809523808,
        "xEnd": 0.30158730158730157,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.30158730158730157,
        "yStart": -0.023809523809523808,
        "xEnd": 0.3047619047619048,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.3047619047619048,
        "yStart": -0.020833333333333332,
        "xEnd": 0.3111111111111111,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.3111111111111111,
        "yStart": -0.020833333333333332,
        "xEnd": 0.3126984126984127,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.3126984126984127,
        "yStart": -0.020833333333333332,
        "xEnd": 0.32063492063492066,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.32063492063492066,
        "yStart": -0.020833333333333332,
        "xEnd": 0.35555555555555557,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.35555555555555557,
        "yStart": -0.023809523809523808,
        "xEnd": 0.35873015873015873,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.35873015873015873,
        "yStart": -0.023809523809523808,
        "xEnd": 0.3634920634920635,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.3634920634920635,
        "yStart": -0.023809523809523808,
        "xEnd": 0.3682539682539683,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.3682539682539683,
        "yStart": -0.023809523809523808,
        "xEnd": 0.37142857142857144,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.37142857142857144,
        "yStart": -0.023809523809523808,
        "xEnd": 0.3761904761904762,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.3761904761904762,
        "yStart": -0.023809523809523808,
        "xEnd": 0.37936507936507935,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.37936507936507935,
        "yStart": -0.023809523809523808,
        "xEnd": 0.38412698412698415,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.38412698412698415,
        "yStart": -0.023809523809523808,
        "xEnd": 0.3904761904761905,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.3904761904761905,
        "yStart": -0.023809523809523808,
        "xEnd": 0.39365079365079364,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.39365079365079364,
        "yStart": -0.023809523809523808,
        "xEnd": 0.3984126984126984,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.3984126984126984,
        "yStart": -0.023809523809523808,
        "xEnd": 0.4031746031746032,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.4031746031746032,
        "yStart": -0.023809523809523808,
        "xEnd": 0.40634920634920635,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.40634920634920635,
        "yStart": -0.020833333333333332,
        "xEnd": 0.4126984126984127,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.4126984126984127,
        "yStart": -0.020833333333333332,
        "xEnd": 0.4142857142857143,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.4142857142857143,
        "yStart": -0.020833333333333332,
        "xEnd": 0.4174603174603175,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.4174603174603175,
        "yStart": -0.020833333333333332,
        "xEnd": 0.4238095238095238,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.4238095238095238,
        "yStart": -0.020833333333333332,
        "xEnd": 0.42857142857142855,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.42857142857142855,
        "yStart": -0.020833333333333332,
        "xEnd": 0.43333333333333335,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.43333333333333335,
        "yStart": -0.020833333333333332,
        "xEnd": 0.43492063492063493,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.43492063492063493,
        "yStart": -0.020833333333333332,
        "xEnd": 0.4444444444444444,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.4444444444444444,
        "yStart": -0.020833333333333332,
        "xEnd": 0.4507936507936508,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.4507936507936508,
        "yStart": -0.020833333333333332,
        "xEnd": 0.45396825396825397,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.45396825396825397,
        "yStart": -0.020833333333333332,
        "xEnd": 0.4603174603174603,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.4603174603174603,
        "yStart": -0.020833333333333332,
        "xEnd": 0.4634920634920635,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.4634920634920635,
        "yStart": -0.020833333333333332,
        "xEnd": 0.46984126984126984,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.46984126984126984,
        "yStart": -0.020833333333333332,
        "xEnd": 0.473015873015873,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.473015873015873,
        "yStart": -0.020833333333333332,
        "xEnd": 0.4777777777777778,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.4777777777777778,
        "yStart": -0.020833333333333332,
        "xEnd": 0.48253968253968255,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.48253968253968255,
        "yStart": -0.020833333333333332,
        "xEnd": 0.4857142857142857,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.4857142857142857,
        "yStart": -0.020833333333333332,
        "xEnd": 0.4936507936507937,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.4936507936507937,
        "yStart": -0.020833333333333332,
        "xEnd": 0.5,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.5,
        "yStart": -0.020833333333333332,
        "xEnd": 0.5047619047619047,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.5047619047619047,
        "yStart": -0.020833333333333332,
        "xEnd": 0.5111111111111111,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.5111111111111111,
        "yStart": -0.023809523809523808,
        "xEnd": 0.5158730158730159,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.5158730158730159,
        "yStart": -0.023809523809523808,
        "xEnd": 0.5190476190476191,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.5190476190476191,
        "yStart": -0.023809523809523808,
        "xEnd": 0.5222222222222223,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.5222222222222223,
        "yStart": -0.023809523809523808,
        "xEnd": 0.526984126984127,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.526984126984127,
        "yStart": -0.023809523809523808,
        "xEnd": 0.5301587301587302,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.5301587301587302,
        "yStart": -0.023809523809523808,
        "xEnd": 0.5333333333333333,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.5333333333333333,
        "yStart": -0.023809523809523808,
        "xEnd": 0.5365079365079365,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.5365079365079365,
        "yStart": -0.023809523809523808,
        "xEnd": 0.5380952380952381,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.5380952380952381,
        "yStart": -0.023809523809523808,
        "xEnd": 0.5412698412698412,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.5412698412698412,
        "yStart": -0.023809523809523808,
        "xEnd": 0.5888888888888889,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.5888888888888889,
        "yStart": -0.023809523809523808,
        "xEnd": 0.5904761904761905,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.5904761904761905,
        "yStart": -0.023809523809523808,
        "xEnd": 0.5984126984126984,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.5984126984126984,
        "yStart": -0.023809523809523808,
        "xEnd": 0.6031746031746031,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.6031746031746031,
        "yStart": -0.023809523809523808,
        "xEnd": 0.6063492063492063,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.6063492063492063,
        "yStart": -0.023809523809523808,
        "xEnd": 0.6126984126984127,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.6126984126984127,
        "yStart": -0.023809523809523808,
        "xEnd": 0.6206349206349207,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.6206349206349207,
        "yStart": -0.023809523809523808,
        "xEnd": 0.6253968253968254,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.6253968253968254,
        "yStart": -0.023809523809523808,
        "xEnd": 0.6285714285714286,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.6285714285714286,
        "yStart": -0.023809523809523808,
        "xEnd": 0.6349206349206349,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.6349206349206349,
        "yStart": -0.023809523809523808,
        "xEnd": 0.638095238095238,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.638095238095238,
        "yStart": -0.023809523809523808,
        "xEnd": 0.6444444444444445,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.6444444444444445,
        "yStart": -0.023809523809523808,
        "xEnd": 0.6460317460317461,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.6460317460317461,
        "yStart": -0.023809523809523808,
        "xEnd": 0.6507936507936508,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.6507936507936508,
        "yStart": -0.023809523809523808,
        "xEnd": 0.653968253968254,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.653968253968254,
        "yStart": -0.023809523809523808,
        "xEnd": 0.6587301587301587,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.6587301587301587,
        "yStart": -0.023809523809523808,
        "xEnd": 0.665079365079365,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.665079365079365,
        "yStart": -0.023809523809523808,
        "xEnd": 0.6698412698412698,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.6698412698412698,
        "yStart": -0.023809523809523808,
        "xEnd": 0.6730158730158731,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.6730158730158731,
        "yStart": -0.023809523809523808,
        "xEnd": 0.680952380952381,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.680952380952381,
        "yStart": -0.026785714285714284,
        "xEnd": 0.6825396825396826,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.6825396825396826,
        "yStart": -0.026785714285714284,
        "xEnd": 0.6873015873015873,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.6873015873015873,
        "yStart": -0.026785714285714284,
        "xEnd": 0.6888888888888889,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.6888888888888889,
        "yStart": -0.026785714285714284,
        "xEnd": 0.692063492063492,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.692063492063492,
        "yStart": -0.026785714285714284,
        "xEnd": 0.6952380952380952,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.6952380952380952,
        "yStart": -0.026785714285714284,
        "xEnd": 0.6984126984126984,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.6984126984126984,
        "yStart": -0.026785714285714284,
        "xEnd": 0.7,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.7,
        "yStart": -0.026785714285714284,
        "xEnd": 0.7047619047619048,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.7047619047619048,
        "yStart": -0.026785714285714284,
        "xEnd": 0.7095238095238096,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.7095238095238096,
        "yStart": -0.026785714285714284,
        "xEnd": 0.7142857142857143,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.7142857142857143,
        "yStart": -0.026785714285714284,
        "xEnd": 0.7158730158730159,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.7158730158730159,
        "yStart": -0.026785714285714284,
        "xEnd": 0.7206349206349206,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.7206349206349206,
        "yStart": -0.026785714285714284,
        "xEnd": 0.7253968253968254,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.7253968253968254,
        "yStart": -0.026785714285714284,
        "xEnd": 0.7253968253968254,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.7253968253968254,
        "yStart": -0.023809523809523808,
        "xEnd": 0.7285714285714285,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.7285714285714285,
        "yStart": -0.023809523809523808,
        "xEnd": 0.7301587301587301,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.7301587301587301,
        "yStart": -0.023809523809523808,
        "xEnd": 0.7317460317460317,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.7317460317460317,
        "yStart": -0.023809523809523808,
        "xEnd": 0.734920634920635,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.734920634920635,
        "yStart": -0.023809523809523808,
        "xEnd": 0.7380952380952381,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.7380952380952381,
        "yStart": -0.023809523809523808,
        "xEnd": 0.7396825396825397,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.7396825396825397,
        "yStart": -0.023809523809523808,
        "xEnd": 0.7444444444444445,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.7444444444444445,
        "yStart": -0.023809523809523808,
        "xEnd": 0.7492063492063492,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.7492063492063492,
        "yStart": -0.023809523809523808,
        "xEnd": 0.7523809523809524,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.7523809523809524,
        "yStart": -0.023809523809523808,
        "xEnd": 0.765079365079365,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.765079365079365,
        "yStart": -0.023809523809523808,
        "xEnd": 0.7666666666666667,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.7666666666666667,
        "yStart": -0.023809523809523808,
        "xEnd": 0.773015873015873,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.773015873015873,
        "yStart": -0.020833333333333332,
        "xEnd": 0.8222222222222222,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.8222222222222222,
        "yStart": -0.020833333333333332,
        "xEnd": 0.8269841269841269,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.8269841269841269,
        "yStart": -0.020833333333333332,
        "xEnd": 0.8301587301587302,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.8301587301587302,
        "yStart": -0.020833333333333332,
        "xEnd": 0.834920634920635,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.834920634920635,
        "yStart": -0.020833333333333332,
        "xEnd": 0.8380952380952381,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.8380952380952381,
        "yStart": -0.020833333333333332,
        "xEnd": 0.8412698412698413,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.8412698412698413,
        "yStart": -0.020833333333333332,
        "xEnd": 0.8444444444444444,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.8444444444444444,
        "yStart": -0.020833333333333332,
        "xEnd": 0.8492063492063492,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.8492063492063492,
        "yStart": -0.020833333333333332,
        "xEnd": 0.8507936507936508,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.8507936507936508,
        "yStart": -0.020833333333333332,
        "xEnd": 0.8555555555555555,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.8555555555555555,
        "yStart": -0.020833333333333332,
        "xEnd": 0.8587301587301587,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.8587301587301587,
        "yStart": -0.020833333333333332,
        "xEnd": 0.8650793650793651,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.8650793650793651,
        "yStart": -0.020833333333333332,
        "xEnd": 0.8777777777777778,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.8777777777777778,
        "yStart": -0.020833333333333332,
        "xEnd": 0.8809523809523809,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.8809523809523809,
        "yStart": -0.020833333333333332,
        "xEnd": 0.8825396825396825,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.8825396825396825,
        "yStart": -0.020833333333333332,
        "xEnd": 0.8841269841269841,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.8841269841269841,
        "yStart": -0.020833333333333332,
        "xEnd": 0.8873015873015873,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.8873015873015873,
        "yStart": -0.020833333333333332,
        "xEnd": 0.8968253968253969,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.8968253968253969,
        "yStart": -0.020833333333333332,
        "xEnd": 0.9,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.9,
        "yStart": -0.020833333333333332,
        "xEnd": 0.9063492063492063,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.9063492063492063,
        "yStart": -0.020833333333333332,
        "xEnd": 0.9095238095238095,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.9095238095238095,
        "yStart": -0.017857142857142856,
        "xEnd": 0.9142857142857143,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.9142857142857143,
        "yStart": -0.017857142857142856,
        "xEnd": 0.9158730158730158,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.9158730158730158,
        "yStart": -0.017857142857142856,
        "xEnd": 0.9222222222222223,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.9222222222222223,
        "yStart": -0.017857142857142856,
        "xEnd": 0.9222222222222223,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.9222222222222223,
        "yStart": -0.017857142857142856,
        "xEnd": 0.9285714285714286,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.9285714285714286,
        "yStart": -0.017857142857142856,
        "xEnd": 0.9333333333333333,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.9333333333333333,
        "yStart": -0.017857142857142856,
        "xEnd": 0.9380952380952381,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.9380952380952381,
        "yStart": -0.017857142857142856,
        "xEnd": 0.9428571428571428,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.9428571428571428,
        "yStart": -0.017857142857142856,
        "xEnd": 0.9571428571428572,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.9571428571428572,
        "yStart": -0.020833333333333332,
        "xEnd": 0.9619047619047619,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.9619047619047619,
        "yStart": -0.020833333333333332,
        "xEnd": 0.9825396825396825,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.9825396825396825,
        "yStart": -0.020833333333333332,
        "xEnd": 0.9857142857142858,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.9857142857142858,
        "yStart": -0.020833333333333332,
        "xEnd": 0.9873015873015873,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 0.9873015873015873,
        "yStart": -0.020833333333333332,
        "xEnd": 1,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1,
        "yStart": -0.020833333333333332,
        "xEnd": 1.0047619047619047,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0047619047619047,
        "yStart": -0.020833333333333332,
        "xEnd": 1.007936507936508,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.007936507936508,
        "yStart": -0.020833333333333332,
        "xEnd": 1.011111111111111,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.011111111111111,
        "yStart": -0.020833333333333332,
        "xEnd": 1.0174603174603174,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0174603174603174,
        "yStart": -0.020833333333333332,
        "xEnd": 1.0285714285714285,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0285714285714285,
        "yStart": -0.020833333333333332,
        "xEnd": 1.0333333333333334,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0333333333333334,
        "yStart": -0.020833333333333332,
        "xEnd": 1.0365079365079366,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0365079365079366,
        "yStart": -0.020833333333333332,
        "xEnd": 1.0380952380952382,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0380952380952382,
        "yStart": -0.020833333333333332,
        "xEnd": 1.0396825396825398,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0396825396825398,
        "yStart": -0.020833333333333332,
        "xEnd": 1.0412698412698413,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0412698412698413,
        "yStart": -0.020833333333333332,
        "xEnd": 1.042857142857143,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.042857142857143,
        "yStart": -0.020833333333333332,
        "xEnd": 1.0476190476190477,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0476190476190477,
        "yStart": -0.020833333333333332,
        "xEnd": 1.0507936507936508,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0507936507936508,
        "yStart": -0.017857142857142856,
        "xEnd": 1.0523809523809524,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0523809523809524,
        "yStart": -0.017857142857142856,
        "xEnd": 1.0603174603174603,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0603174603174603,
        "yStart": -0.017857142857142856,
        "xEnd": 1.0666666666666667,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0666666666666667,
        "yStart": -0.017857142857142856,
        "xEnd": 1.0698412698412698,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0698412698412698,
        "yStart": -0.017857142857142856,
        "xEnd": 1.0746031746031746,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0746031746031746,
        "yStart": -0.017857142857142856,
        "xEnd": 1.0761904761904761,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0761904761904761,
        "yStart": -0.017857142857142856,
        "xEnd": 1.0777777777777777,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0777777777777777,
        "yStart": -0.017857142857142856,
        "xEnd": 1.0793650793650793,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0793650793650793,
        "yStart": -0.017857142857142856,
        "xEnd": 1.0793650793650793,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0793650793650793,
        "yStart": -0.017857142857142856,
        "xEnd": 1.0809523809523809,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0809523809523809,
        "yStart": -0.017857142857142856,
        "xEnd": 1.0809523809523809,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0809523809523809,
        "yStart": -0.017857142857142856,
        "xEnd": 1.0825396825396825,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0825396825396825,
        "yStart": -0.017857142857142856,
        "xEnd": 1.084126984126984,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.084126984126984,
        "yStart": -0.017857142857142856,
        "xEnd": 1.0873015873015872,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0873015873015872,
        "yStart": -0.017857142857142856,
        "xEnd": 1.0936507936507935,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0936507936507935,
        "yStart": -0.017857142857142856,
        "xEnd": 1.0936507936507935,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0936507936507935,
        "yStart": -0.017857142857142856,
        "xEnd": 1.0952380952380953,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0952380952380953,
        "yStart": -0.017857142857142856,
        "xEnd": 1.0952380952380953,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0952380952380953,
        "yStart": -0.017857142857142856,
        "xEnd": 1.0952380952380953,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.0952380952380953,
        "yStart": -0.017857142857142856,
        "xEnd": 1.1126984126984127,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.1126984126984127,
        "yStart": -0.017857142857142856,
        "xEnd": 1.1126984126984127,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.1126984126984127,
        "yStart": -0.017857142857142856,
        "xEnd": 1.1142857142857143,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.1142857142857143,
        "yStart": -0.017857142857142856,
        "xEnd": 1.1142857142857143,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.1142857142857143,
        "yStart": -0.017857142857142856,
        "xEnd": 1.115873015873016,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.115873015873016,
        "yStart": -0.017857142857142856,
        "xEnd": 1.1174603174603175,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.1174603174603175,
        "yStart": -0.017857142857142856,
        "xEnd": 1.1206349206349207,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.1206349206349207,
        "yStart": -0.017857142857142856,
        "xEnd": 1.1238095238095238,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.1238095238095238,
        "yStart": -0.017857142857142856,
        "xEnd": 1.1253968253968254,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.1253968253968254,
        "yStart": -0.017857142857142856,
        "xEnd": 1.126984126984127,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.126984126984127,
        "yStart": -0.017857142857142856,
        "xEnd": 1.126984126984127,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.126984126984127,
        "yStart": -0.017857142857142856,
        "xEnd": 1.126984126984127,
        "yEnd": -0.01488095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.126984126984127,
        "yStart": -0.01488095238095238,
        "xEnd": 1.126984126984127,
        "yEnd": -0.01488095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.126984126984127,
        "yStart": -0.01488095238095238,
        "xEnd": 1.1285714285714286,
        "yEnd": -0.01488095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.1285714285714286,
        "yStart": -0.01488095238095238,
        "xEnd": 1.1285714285714286,
        "yEnd": -0.01488095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.1285714285714286,
        "yStart": -0.01488095238095238,
        "xEnd": 1.1301587301587301,
        "yEnd": -0.01488095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.1301587301587301,
        "yStart": -0.01488095238095238,
        "xEnd": 1.1333333333333333,
        "yEnd": -0.01488095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.1333333333333333,
        "yStart": -0.01488095238095238,
        "xEnd": 1.1349206349206349,
        "yEnd": -0.01488095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 9
    },
    {
        "xStart": 1.1142857142857143,
        "yStart": 0.020833333333333332,
        "xEnd": 1.1142857142857143,
        "yEnd": 0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1142857142857143,
        "yStart": 0.020833333333333332,
        "xEnd": 1.115873015873016,
        "yEnd": 0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.115873015873016,
        "yStart": 0.020833333333333332,
        "xEnd": 1.1174603174603175,
        "yEnd": 0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1174603174603175,
        "yStart": 0.017857142857142856,
        "xEnd": 1.1222222222222222,
        "yEnd": 0.011904761904761904,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1222222222222222,
        "yStart": 0.011904761904761904,
        "xEnd": 1.1253968253968254,
        "yEnd": 0.008928571428571428,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1253968253968254,
        "yStart": 0.008928571428571428,
        "xEnd": 1.1333333333333333,
        "yEnd": 0.002976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1333333333333333,
        "yStart": 0.002976190476190476,
        "xEnd": 1.1349206349206349,
        "yEnd": 0.002976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1349206349206349,
        "yStart": 0.002976190476190476,
        "xEnd": 1.1365079365079365,
        "yEnd": 0.002976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1365079365079365,
        "yStart": 0.002976190476190476,
        "xEnd": 1.1365079365079365,
        "yEnd": 0,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1365079365079365,
        "yStart": 0,
        "xEnd": 1.1365079365079365,
        "yEnd": 0,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1365079365079365,
        "yStart": 0,
        "xEnd": 1.138095238095238,
        "yEnd": 0,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.138095238095238,
        "yStart": 0,
        "xEnd": 1.138095238095238,
        "yEnd": 0,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.138095238095238,
        "yStart": 0,
        "xEnd": 1.138095238095238,
        "yEnd": 0,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.138095238095238,
        "yStart": 0,
        "xEnd": 1.1365079365079365,
        "yEnd": 0,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1365079365079365,
        "yStart": 0,
        "xEnd": 1.1365079365079365,
        "yEnd": 0,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1365079365079365,
        "yStart": 0,
        "xEnd": 1.1365079365079365,
        "yEnd": -0.002976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1365079365079365,
        "yStart": -0.002976190476190476,
        "xEnd": 1.1365079365079365,
        "yEnd": -0.002976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1365079365079365,
        "yStart": -0.002976190476190476,
        "xEnd": 1.1365079365079365,
        "yEnd": -0.002976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1365079365079365,
        "yStart": -0.002976190476190476,
        "xEnd": 1.1333333333333333,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1333333333333333,
        "yStart": -0.023809523809523808,
        "xEnd": 1.1317460317460317,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1317460317460317,
        "yStart": -0.026785714285714284,
        "xEnd": 1.1317460317460317,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1317460317460317,
        "yStart": -0.026785714285714284,
        "xEnd": 1.1317460317460317,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1317460317460317,
        "yStart": -0.02976190476190476,
        "xEnd": 1.1317460317460317,
        "yEnd": -0.03273809523809524,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1317460317460317,
        "yStart": -0.03273809523809524,
        "xEnd": 1.1301587301587301,
        "yEnd": -0.03571428571428571,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1301587301587301,
        "yStart": -0.03571428571428571,
        "xEnd": 1.1301587301587301,
        "yEnd": -0.03571428571428571,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1301587301587301,
        "yStart": -0.03571428571428571,
        "xEnd": 1.1301587301587301,
        "yEnd": -0.03869047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1301587301587301,
        "yStart": -0.03869047619047619,
        "xEnd": 1.1301587301587301,
        "yEnd": -0.03869047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1301587301587301,
        "yStart": -0.03869047619047619,
        "xEnd": 1.1285714285714286,
        "yEnd": -0.03869047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1285714285714286,
        "yStart": -0.03869047619047619,
        "xEnd": 1.1285714285714286,
        "yEnd": -0.041666666666666664,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1285714285714286,
        "yStart": -0.041666666666666664,
        "xEnd": 1.1285714285714286,
        "yEnd": -0.041666666666666664,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1285714285714286,
        "yStart": -0.041666666666666664,
        "xEnd": 1.1285714285714286,
        "yEnd": -0.041666666666666664,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1285714285714286,
        "yStart": -0.041666666666666664,
        "xEnd": 1.1285714285714286,
        "yEnd": -0.041666666666666664,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1285714285714286,
        "yStart": -0.041666666666666664,
        "xEnd": 1.1285714285714286,
        "yEnd": -0.044642857142857144,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1285714285714286,
        "yStart": -0.044642857142857144,
        "xEnd": 1.1285714285714286,
        "yEnd": -0.044642857142857144,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1285714285714286,
        "yStart": -0.044642857142857144,
        "xEnd": 1.1285714285714286,
        "yEnd": -0.044642857142857144,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1285714285714286,
        "yStart": -0.044642857142857144,
        "xEnd": 1.126984126984127,
        "yEnd": -0.044642857142857144,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.126984126984127,
        "yStart": -0.044642857142857144,
        "xEnd": 1.126984126984127,
        "yEnd": -0.044642857142857144,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.126984126984127,
        "yStart": -0.044642857142857144,
        "xEnd": 1.126984126984127,
        "yEnd": -0.047619047619047616,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.126984126984127,
        "yStart": -0.047619047619047616,
        "xEnd": 1.126984126984127,
        "yEnd": -0.047619047619047616,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.126984126984127,
        "yStart": -0.047619047619047616,
        "xEnd": 1.126984126984127,
        "yEnd": -0.047619047619047616,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.126984126984127,
        "yStart": -0.047619047619047616,
        "xEnd": 1.126984126984127,
        "yEnd": -0.050595238095238096,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.126984126984127,
        "yStart": -0.050595238095238096,
        "xEnd": 1.1253968253968254,
        "yEnd": -0.050595238095238096,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1253968253968254,
        "yStart": -0.050595238095238096,
        "xEnd": 1.1253968253968254,
        "yEnd": -0.050595238095238096,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1253968253968254,
        "yStart": -0.050595238095238096,
        "xEnd": 1.1253968253968254,
        "yEnd": -0.050595238095238096,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1253968253968254,
        "yStart": -0.050595238095238096,
        "xEnd": 1.1253968253968254,
        "yEnd": -0.050595238095238096,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1253968253968254,
        "yStart": -0.050595238095238096,
        "xEnd": 1.1253968253968254,
        "yEnd": -0.05357142857142857,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1253968253968254,
        "yStart": -0.05357142857142857,
        "xEnd": 1.1253968253968254,
        "yEnd": -0.05357142857142857,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1253968253968254,
        "yStart": -0.05357142857142857,
        "xEnd": 1.1253968253968254,
        "yEnd": -0.05357142857142857,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1253968253968254,
        "yStart": -0.05357142857142857,
        "xEnd": 1.1253968253968254,
        "yEnd": -0.05654761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1253968253968254,
        "yStart": -0.05654761904761905,
        "xEnd": 1.1238095238095238,
        "yEnd": -0.05654761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1238095238095238,
        "yStart": -0.05654761904761905,
        "xEnd": 1.1238095238095238,
        "yEnd": -0.05654761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1238095238095238,
        "yStart": -0.05654761904761905,
        "xEnd": 1.1238095238095238,
        "yEnd": -0.05654761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1238095238095238,
        "yStart": -0.05654761904761905,
        "xEnd": 1.1238095238095238,
        "yEnd": -0.05654761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1238095238095238,
        "yStart": -0.05654761904761905,
        "xEnd": 1.1238095238095238,
        "yEnd": -0.05952380952380952,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": 1.1238095238095238,
        "yStart": -0.05952380952380952,
        "xEnd": 1.1238095238095238,
        "yEnd": -0.05952380952380952,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 10
    },
    {
        "xStart": -0.025396825396825397,
        "yStart": 1.1041666666666667,
        "xEnd": -0.025396825396825397,
        "yEnd": 1.1041666666666667,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.025396825396825397,
        "yStart": 1.1041666666666667,
        "xEnd": -0.025396825396825397,
        "yEnd": 1.1041666666666667,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.025396825396825397,
        "yStart": 1.1041666666666667,
        "xEnd": -0.025396825396825397,
        "yEnd": 1.1041666666666667,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.025396825396825397,
        "yStart": 1.1041666666666667,
        "xEnd": -0.025396825396825397,
        "yEnd": 1.1011904761904763,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.025396825396825397,
        "yStart": 1.1011904761904763,
        "xEnd": -0.025396825396825397,
        "yEnd": 1.0982142857142858,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.025396825396825397,
        "yStart": 1.0982142857142858,
        "xEnd": -0.025396825396825397,
        "yEnd": 1.0982142857142858,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.025396825396825397,
        "yStart": 1.0982142857142858,
        "xEnd": -0.025396825396825397,
        "yEnd": 1.0952380952380953,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.025396825396825397,
        "yStart": 1.0952380952380953,
        "xEnd": -0.025396825396825397,
        "yEnd": 1.0892857142857142,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.025396825396825397,
        "yStart": 1.0892857142857142,
        "xEnd": -0.025396825396825397,
        "yEnd": 1.0714285714285714,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.025396825396825397,
        "yStart": 1.0714285714285714,
        "xEnd": -0.025396825396825397,
        "yEnd": 1.068452380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.025396825396825397,
        "yStart": 1.068452380952381,
        "xEnd": -0.025396825396825397,
        "yEnd": 1.0595238095238095,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.025396825396825397,
        "yStart": 1.0595238095238095,
        "xEnd": -0.025396825396825397,
        "yEnd": 1.0505952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.025396825396825397,
        "yStart": 1.0505952380952381,
        "xEnd": -0.025396825396825397,
        "yEnd": 1.0386904761904763,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.025396825396825397,
        "yStart": 1.0386904761904763,
        "xEnd": -0.025396825396825397,
        "yEnd": 1.0327380952380953,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.025396825396825397,
        "yStart": 1.0327380952380953,
        "xEnd": -0.025396825396825397,
        "yEnd": 1.0297619047619047,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.025396825396825397,
        "yStart": 1.0297619047619047,
        "xEnd": -0.023809523809523808,
        "yEnd": 0.9821428571428571,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.023809523809523808,
        "yStart": 0.9821428571428571,
        "xEnd": -0.022222222222222223,
        "yEnd": 0.9761904761904762,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.022222222222222223,
        "yStart": 0.9761904761904762,
        "xEnd": -0.022222222222222223,
        "yEnd": 0.9702380952380952,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.022222222222222223,
        "yStart": 0.9702380952380952,
        "xEnd": -0.022222222222222223,
        "yEnd": 0.9642857142857143,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.022222222222222223,
        "yStart": 0.9642857142857143,
        "xEnd": -0.022222222222222223,
        "yEnd": 0.9613095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.022222222222222223,
        "yStart": 0.9613095238095238,
        "xEnd": -0.022222222222222223,
        "yEnd": 0.9553571428571429,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.022222222222222223,
        "yStart": 0.9553571428571429,
        "xEnd": -0.020634920634920634,
        "yEnd": 0.9464285714285714,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.020634920634920634,
        "yStart": 0.9464285714285714,
        "xEnd": -0.020634920634920634,
        "yEnd": 0.9404761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.020634920634920634,
        "yStart": 0.9404761904761905,
        "xEnd": -0.020634920634920634,
        "yEnd": 0.9345238095238095,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.020634920634920634,
        "yStart": 0.9345238095238095,
        "xEnd": -0.020634920634920634,
        "yEnd": 0.9285714285714286,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.020634920634920634,
        "yStart": 0.9285714285714286,
        "xEnd": -0.020634920634920634,
        "yEnd": 0.9255952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.020634920634920634,
        "yStart": 0.9255952380952381,
        "xEnd": -0.020634920634920634,
        "yEnd": 0.9226190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.020634920634920634,
        "yStart": 0.9226190476190477,
        "xEnd": -0.01904761904761905,
        "yEnd": 0.9166666666666666,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.01904761904761905,
        "yStart": 0.9166666666666666,
        "xEnd": -0.01904761904761905,
        "yEnd": 0.9107142857142857,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.01904761904761905,
        "yStart": 0.9107142857142857,
        "xEnd": -0.01904761904761905,
        "yEnd": 0.9047619047619048,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.01904761904761905,
        "yStart": 0.9047619047619048,
        "xEnd": -0.01904761904761905,
        "yEnd": 0.9017857142857143,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.01904761904761905,
        "yStart": 0.9017857142857143,
        "xEnd": -0.01904761904761905,
        "yEnd": 0.9017857142857143,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.01904761904761905,
        "yStart": 0.9017857142857143,
        "xEnd": -0.01746031746031746,
        "yEnd": 0.8988095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.01746031746031746,
        "yStart": 0.8988095238095238,
        "xEnd": -0.01746031746031746,
        "yEnd": 0.8898809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.01746031746031746,
        "yStart": 0.8898809523809523,
        "xEnd": -0.01746031746031746,
        "yEnd": 0.8839285714285714,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.01746031746031746,
        "yStart": 0.8839285714285714,
        "xEnd": -0.01746031746031746,
        "yEnd": 0.8779761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.01746031746031746,
        "yStart": 0.8779761904761905,
        "xEnd": -0.01746031746031746,
        "yEnd": 0.8779761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.01746031746031746,
        "yStart": 0.8779761904761905,
        "xEnd": -0.01746031746031746,
        "yEnd": 0.8720238095238095,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.01746031746031746,
        "yStart": 0.8720238095238095,
        "xEnd": -0.01746031746031746,
        "yEnd": 0.8660714285714286,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.01746031746031746,
        "yStart": 0.8660714285714286,
        "xEnd": -0.01746031746031746,
        "yEnd": 0.8601190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.01746031746031746,
        "yStart": 0.8601190476190477,
        "xEnd": -0.01746031746031746,
        "yEnd": 0.8601190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.01746031746031746,
        "yStart": 0.8601190476190477,
        "xEnd": -0.01746031746031746,
        "yEnd": 0.8541666666666666,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.01746031746031746,
        "yStart": 0.8541666666666666,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.8482142857142857,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.8482142857142857,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.8452380952380952,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.8452380952380952,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.8422619047619048,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.8422619047619048,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.8363095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.8363095238095238,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.8273809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.8273809523809523,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.8184523809523809,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.8184523809523809,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.8125,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.8125,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.8035714285714286,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.8035714285714286,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.7946428571428571,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.7946428571428571,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.7857142857142857,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.7857142857142857,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.7738095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.7738095238095238,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.7619047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.7619047619047619,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.75,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.75,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.7380952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.7380952380952381,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.7261904761904762,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.7261904761904762,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.7142857142857143,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.7142857142857143,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.6994047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.6994047619047619,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.6845238095238095,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.6845238095238095,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.6726190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.6726190476190477,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.6636904761904762,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.6636904761904762,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.6488095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.6488095238095238,
        "xEnd": -0.015873015873015872,
        "yEnd": 0.6369047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 0.6369047619047619,
        "xEnd": -0.014285714285714285,
        "yEnd": 0.625,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.014285714285714285,
        "yStart": 0.625,
        "xEnd": -0.014285714285714285,
        "yEnd": 0.6190476190476191,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.014285714285714285,
        "yStart": 0.6190476190476191,
        "xEnd": -0.014285714285714285,
        "yEnd": 0.6101190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.014285714285714285,
        "yStart": 0.6101190476190477,
        "xEnd": -0.014285714285714285,
        "yEnd": 0.6041666666666666,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.014285714285714285,
        "yStart": 0.6041666666666666,
        "xEnd": -0.014285714285714285,
        "yEnd": 0.5952380952380952,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.014285714285714285,
        "yStart": 0.5952380952380952,
        "xEnd": -0.014285714285714285,
        "yEnd": 0.5952380952380952,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.014285714285714285,
        "yStart": 0.5952380952380952,
        "xEnd": -0.014285714285714285,
        "yEnd": 0.5684523809523809,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.014285714285714285,
        "yStart": 0.5684523809523809,
        "xEnd": -0.014285714285714285,
        "yEnd": 0.5625,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.014285714285714285,
        "yStart": 0.5625,
        "xEnd": -0.012698412698412698,
        "yEnd": 0.5595238095238095,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.012698412698412698,
        "yStart": 0.5595238095238095,
        "xEnd": -0.012698412698412698,
        "yEnd": 0.5565476190476191,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.012698412698412698,
        "yStart": 0.5565476190476191,
        "xEnd": -0.012698412698412698,
        "yEnd": 0.5565476190476191,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.012698412698412698,
        "yStart": 0.5565476190476191,
        "xEnd": -0.012698412698412698,
        "yEnd": 0.5535714285714286,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.012698412698412698,
        "yStart": 0.5535714285714286,
        "xEnd": -0.012698412698412698,
        "yEnd": 0.5476190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.012698412698412698,
        "yStart": 0.5476190476190477,
        "xEnd": -0.012698412698412698,
        "yEnd": 0.5446428571428571,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.012698412698412698,
        "yStart": 0.5446428571428571,
        "xEnd": -0.012698412698412698,
        "yEnd": 0.5446428571428571,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.012698412698412698,
        "yStart": 0.5446428571428571,
        "xEnd": -0.012698412698412698,
        "yEnd": 0.5386904761904762,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.012698412698412698,
        "yStart": 0.5386904761904762,
        "xEnd": -0.012698412698412698,
        "yEnd": 0.5327380952380952,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.012698412698412698,
        "yStart": 0.5327380952380952,
        "xEnd": -0.012698412698412698,
        "yEnd": 0.5297619047619048,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.012698412698412698,
        "yStart": 0.5297619047619048,
        "xEnd": -0.012698412698412698,
        "yEnd": 0.5238095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.012698412698412698,
        "yStart": 0.5238095238095238,
        "xEnd": -0.012698412698412698,
        "yEnd": 0.5208333333333334,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.012698412698412698,
        "yStart": 0.5208333333333334,
        "xEnd": -0.012698412698412698,
        "yEnd": 0.5148809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.012698412698412698,
        "yStart": 0.5148809523809523,
        "xEnd": -0.012698412698412698,
        "yEnd": 0.5148809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.012698412698412698,
        "yStart": 0.5148809523809523,
        "xEnd": -0.012698412698412698,
        "yEnd": 0.5119047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.012698412698412698,
        "yStart": 0.5119047619047619,
        "xEnd": -0.011111111111111112,
        "yEnd": 0.5089285714285714,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.011111111111111112,
        "yStart": 0.5089285714285714,
        "xEnd": -0.011111111111111112,
        "yEnd": 0.5059523809523809,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.011111111111111112,
        "yStart": 0.5059523809523809,
        "xEnd": -0.011111111111111112,
        "yEnd": 0.5,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.011111111111111112,
        "yStart": 0.5,
        "xEnd": -0.011111111111111112,
        "yEnd": 0.49702380952380953,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.011111111111111112,
        "yStart": 0.49702380952380953,
        "xEnd": -0.011111111111111112,
        "yEnd": 0.49107142857142855,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.011111111111111112,
        "yStart": 0.49107142857142855,
        "xEnd": -0.009523809523809525,
        "yEnd": 0.4732142857142857,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.009523809523809525,
        "yStart": 0.4732142857142857,
        "xEnd": -0.009523809523809525,
        "yEnd": 0.46726190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.009523809523809525,
        "yStart": 0.46726190476190477,
        "xEnd": -0.007936507936507936,
        "yEnd": 0.46130952380952384,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.007936507936507936,
        "yStart": 0.46130952380952384,
        "xEnd": -0.007936507936507936,
        "yEnd": 0.45535714285714285,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.007936507936507936,
        "yStart": 0.45535714285714285,
        "xEnd": -0.007936507936507936,
        "yEnd": 0.4494047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.007936507936507936,
        "yStart": 0.4494047619047619,
        "xEnd": -0.007936507936507936,
        "yEnd": 0.44047619047619047,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.007936507936507936,
        "yStart": 0.44047619047619047,
        "xEnd": -0.007936507936507936,
        "yEnd": 0.43154761904761907,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.007936507936507936,
        "yStart": 0.43154761904761907,
        "xEnd": -0.007936507936507936,
        "yEnd": 0.42857142857142855,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.007936507936507936,
        "yStart": 0.42857142857142855,
        "xEnd": -0.007936507936507936,
        "yEnd": 0.41964285714285715,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.007936507936507936,
        "yStart": 0.41964285714285715,
        "xEnd": -0.007936507936507936,
        "yEnd": 0.40773809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.007936507936507936,
        "yStart": 0.40773809523809523,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.39880952380952384,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.39880952380952384,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.3898809523809524,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.3898809523809524,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.38095238095238093,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.38095238095238093,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.375,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.375,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.36904761904761907,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.36904761904761907,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.3630952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.3630952380952381,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.3541666666666667,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.3541666666666667,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.3541666666666667,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.3541666666666667,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.34226190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.34226190476190477,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.33630952380952384,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.33630952380952384,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.3273809523809524,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.3273809523809524,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.32142857142857145,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.32142857142857145,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.31547619047619047,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.31547619047619047,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.30952380952380953,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.30952380952380953,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.28869047619047616,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.28869047619047616,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.28273809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.28273809523809523,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.2767857142857143,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.2767857142857143,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.26785714285714285,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.26785714285714285,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.25892857142857145,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.25892857142857145,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.25595238095238093,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.25595238095238093,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.24702380952380953,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.24702380952380953,
        "xEnd": -0.006349206349206349,
        "yEnd": 0.23809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.006349206349206349,
        "yStart": 0.23809523809523808,
        "xEnd": -0.004761904761904762,
        "yEnd": 0.23511904761904762,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": 0.23511904761904762,
        "xEnd": -0.004761904761904762,
        "yEnd": 0.22916666666666666,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": 0.22916666666666666,
        "xEnd": -0.004761904761904762,
        "yEnd": 0.22916666666666666,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": 0.22916666666666666,
        "xEnd": -0.0031746031746031746,
        "yEnd": 0.16071428571428573,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 0.16071428571428573,
        "xEnd": -0.0031746031746031746,
        "yEnd": 0.15476190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 0.15476190476190477,
        "xEnd": -0.0031746031746031746,
        "yEnd": 0.14285714285714285,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 0.14285714285714285,
        "xEnd": -0.0031746031746031746,
        "yEnd": 0.13392857142857142,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 0.13392857142857142,
        "xEnd": -0.0031746031746031746,
        "yEnd": 0.12797619047619047,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 0.12797619047619047,
        "xEnd": -0.0031746031746031746,
        "yEnd": 0.125,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 0.125,
        "xEnd": -0.0031746031746031746,
        "yEnd": 0.11904761904761904,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 0.11904761904761904,
        "xEnd": -0.0031746031746031746,
        "yEnd": 0.1130952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 0.1130952380952381,
        "xEnd": -0.0031746031746031746,
        "yEnd": 0.1130952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 0.1130952380952381,
        "xEnd": -0.0031746031746031746,
        "yEnd": 0.10714285714285714,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 0.10714285714285714,
        "xEnd": -0.0031746031746031746,
        "yEnd": 0.10119047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 0.10119047619047619,
        "xEnd": -0.0031746031746031746,
        "yEnd": 0.09821428571428571,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 0.09821428571428571,
        "xEnd": -0.0031746031746031746,
        "yEnd": 0.09226190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 0.09226190476190477,
        "xEnd": -0.0031746031746031746,
        "yEnd": 0.08630952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 0.08630952380952381,
        "xEnd": -0.0031746031746031746,
        "yEnd": 0.08035714285714286,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 0.08035714285714286,
        "xEnd": -0.0031746031746031746,
        "yEnd": 0.0744047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 0.0744047619047619,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.07142857142857142,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.07142857142857142,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.06547619047619048,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.06547619047619048,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.05952380952380952,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.05952380952380952,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.05952380952380952,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.05952380952380952,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.05357142857142857,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.05357142857142857,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.050595238095238096,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.050595238095238096,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.044642857142857144,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.044642857142857144,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.044642857142857144,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.044642857142857144,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.041666666666666664,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.041666666666666664,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.03869047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.03869047619047619,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.03869047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.03869047619047619,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.03571428571428571,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.03571428571428571,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.03273809523809524,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.03273809523809524,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.03273809523809524,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.03273809523809524,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.02976190476190476,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.02976190476190476,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.026785714285714284,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.017857142857142856,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.01488095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.01488095238095238,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.011904761904761904,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.011904761904761904,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.008928571428571428,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.008928571428571428,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.005952380952380952,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.005952380952380952,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0.002976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0.002976190476190476,
        "xEnd": -0.0015873015873015873,
        "yEnd": 0,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 0,
        "xEnd": -0.0015873015873015873,
        "yEnd": -0.002976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.002976190476190476,
        "xEnd": -0.0015873015873015873,
        "yEnd": -0.002976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.002976190476190476,
        "xEnd": -0.0015873015873015873,
        "yEnd": -0.005952380952380952,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.005952380952380952,
        "xEnd": -0.0015873015873015873,
        "yEnd": -0.005952380952380952,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.005952380952380952,
        "xEnd": -0.0015873015873015873,
        "yEnd": -0.008928571428571428,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.008928571428571428,
        "xEnd": -0.0015873015873015873,
        "yEnd": -0.008928571428571428,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.008928571428571428,
        "xEnd": -0.0015873015873015873,
        "yEnd": -0.01488095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.01488095238095238,
        "xEnd": -0.0015873015873015873,
        "yEnd": -0.01488095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.01488095238095238,
        "xEnd": -0.0015873015873015873,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.017857142857142856,
        "xEnd": -0.0015873015873015873,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.017857142857142856,
        "xEnd": -0.0015873015873015873,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.020833333333333332,
        "xEnd": -0.0015873015873015873,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.020833333333333332,
        "xEnd": -0.0015873015873015873,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.023809523809523808,
        "xEnd": -0.0015873015873015873,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.023809523809523808,
        "xEnd": -0.0015873015873015873,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.023809523809523808,
        "xEnd": -0.0015873015873015873,
        "yEnd": -0.026785714285714284,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.026785714285714284,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.02976190476190476,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.02976190476190476,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.02976190476190476,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.02976190476190476,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.03273809523809524,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.03273809523809524,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.03571428571428571,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.03571428571428571,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.03869047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.03869047619047619,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.03869047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.03869047619047619,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.050595238095238096,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.050595238095238096,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.050595238095238096,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.050595238095238096,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.05357142857142857,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.05357142857142857,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.05357142857142857,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.05357142857142857,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.05357142857142857,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.05357142857142857,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.05654761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.05654761904761905,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.05654761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.05654761904761905,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.05654761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.05654761904761905,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.05952380952380952,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.05952380952380952,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.0625,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.0625,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.0625,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.0625,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.06547619047619048,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.06547619047619048,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.06547619047619048,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.06547619047619048,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.06547619047619048,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.06547619047619048,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.06845238095238096,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.06845238095238096,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.06845238095238096,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.06845238095238096,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.06845238095238096,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.06845238095238096,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.06845238095238096,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 16
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.06845238095238096,
        "xEnd": -0.0015873015873015873,
        "yEnd": -0.06845238095238096,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.06845238095238096,
        "xEnd": -0.0015873015873015873,
        "yEnd": -0.06845238095238096,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": -0.06845238095238096,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.07142857142857142,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.07142857142857142,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.0744047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.0744047619047619,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.0744047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.0744047619047619,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.0744047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.0744047619047619,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.07738095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.07738095238095238,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.08035714285714286,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.08035714285714286,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.08035714285714286,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.08035714285714286,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.08333333333333333,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.08333333333333333,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.08333333333333333,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.08333333333333333,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.08630952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.08630952380952381,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.08630952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.08630952380952381,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.08630952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.08630952380952381,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.08928571428571429,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.08928571428571429,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.08928571428571429,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.08928571428571429,
        "xEnd": -0.0031746031746031746,
        "yEnd": -0.08928571428571429,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": -0.08928571428571429,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.09226190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.09226190476190477,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.09226190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.09226190476190477,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.09523809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.09523809523809523,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.09523809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.09523809523809523,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.09523809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.09523809523809523,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.09523809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.09523809523809523,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.09821428571428571,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.09821428571428571,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.09821428571428571,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.09821428571428571,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.09821428571428571,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.09821428571428571,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.10119047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.10119047619047619,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.10119047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.10119047619047619,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.10119047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.10119047619047619,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.10416666666666667,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.10416666666666667,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.10416666666666667,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.10416666666666667,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.10714285714285714,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.10714285714285714,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.10714285714285714,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.10714285714285714,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.10714285714285714,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.10714285714285714,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.11011904761904762,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.11011904761904762,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.11011904761904762,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.11011904761904762,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.11011904761904762,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.11011904761904762,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.1130952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.1130952380952381,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.1130952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.1130952380952381,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.1130952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.1130952380952381,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.1130952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.1130952380952381,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.11607142857142858,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.11607142857142858,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.11607142857142858,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.11607142857142858,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.11607142857142858,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.11607142857142858,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.11904761904761904,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.11904761904761904,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.11904761904761904,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": -0.11904761904761904,
        "xEnd": -0.004761904761904762,
        "yEnd": -0.11904761904761904,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 17
    },
    {
        "xStart": -0.026984126984126985,
        "yStart": 1.0982142857142858,
        "xEnd": -0.026984126984126985,
        "yEnd": 1.0982142857142858,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.026984126984126985,
        "yStart": 1.0982142857142858,
        "xEnd": -0.02857142857142857,
        "yEnd": 1.0982142857142858,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.02857142857142857,
        "yStart": 1.0982142857142858,
        "xEnd": -0.02857142857142857,
        "yEnd": 1.0952380952380953,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.02857142857142857,
        "yStart": 1.0952380952380953,
        "xEnd": -0.03492063492063492,
        "yEnd": 1.0833333333333333,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.03492063492063492,
        "yStart": 1.0833333333333333,
        "xEnd": -0.03492063492063492,
        "yEnd": 1.0803571428571428,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.03492063492063492,
        "yStart": 1.0803571428571428,
        "xEnd": -0.03650793650793651,
        "yEnd": 1.0773809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.03650793650793651,
        "yStart": 1.0773809523809523,
        "xEnd": -0.0380952380952381,
        "yEnd": 1.0773809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.0380952380952381,
        "yStart": 1.0773809523809523,
        "xEnd": -0.03968253968253968,
        "yEnd": 1.0714285714285714,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.03968253968253968,
        "yStart": 1.0714285714285714,
        "xEnd": -0.03968253968253968,
        "yEnd": 1.068452380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.03968253968253968,
        "yStart": 1.068452380952381,
        "xEnd": -0.04126984126984127,
        "yEnd": 1.0654761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.04126984126984127,
        "yStart": 1.0654761904761905,
        "xEnd": -0.04285714285714286,
        "yEnd": 1.0625,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.04285714285714286,
        "yStart": 1.0625,
        "xEnd": -0.04285714285714286,
        "yEnd": 1.0625,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.04285714285714286,
        "yStart": 1.0625,
        "xEnd": -0.04285714285714286,
        "yEnd": 1.0625,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.04285714285714286,
        "yStart": 1.0625,
        "xEnd": -0.04285714285714286,
        "yEnd": 1.0595238095238095,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.04285714285714286,
        "yStart": 1.0595238095238095,
        "xEnd": -0.044444444444444446,
        "yEnd": 1.0595238095238095,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.044444444444444446,
        "yStart": 1.0595238095238095,
        "xEnd": -0.044444444444444446,
        "yEnd": 1.056547619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.044444444444444446,
        "yStart": 1.056547619047619,
        "xEnd": -0.044444444444444446,
        "yEnd": 1.056547619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.044444444444444446,
        "yStart": 1.056547619047619,
        "xEnd": -0.046031746031746035,
        "yEnd": 1.056547619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.046031746031746035,
        "yStart": 1.056547619047619,
        "xEnd": -0.046031746031746035,
        "yEnd": 1.0535714285714286,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.046031746031746035,
        "yStart": 1.0535714285714286,
        "xEnd": -0.046031746031746035,
        "yEnd": 1.0535714285714286,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.046031746031746035,
        "yStart": 1.0535714285714286,
        "xEnd": -0.046031746031746035,
        "yEnd": 1.0505952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.046031746031746035,
        "yStart": 1.0505952380952381,
        "xEnd": -0.046031746031746035,
        "yEnd": 1.0505952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.046031746031746035,
        "yStart": 1.0505952380952381,
        "xEnd": -0.047619047619047616,
        "yEnd": 1.0505952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.047619047619047616,
        "yStart": 1.0505952380952381,
        "xEnd": -0.047619047619047616,
        "yEnd": 1.0505952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.047619047619047616,
        "yStart": 1.0505952380952381,
        "xEnd": -0.047619047619047616,
        "yEnd": 1.0476190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.047619047619047616,
        "yStart": 1.0476190476190477,
        "xEnd": -0.047619047619047616,
        "yEnd": 1.0476190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.047619047619047616,
        "yStart": 1.0476190476190477,
        "xEnd": -0.047619047619047616,
        "yEnd": 1.0476190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 20
    },
    {
        "xStart": -0.022222222222222223,
        "yStart": 1.0892857142857142,
        "xEnd": -0.022222222222222223,
        "yEnd": 1.0892857142857142,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.022222222222222223,
        "yStart": 1.0892857142857142,
        "xEnd": -0.020634920634920634,
        "yEnd": 1.0892857142857142,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.020634920634920634,
        "yStart": 1.0892857142857142,
        "xEnd": -0.020634920634920634,
        "yEnd": 1.0892857142857142,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.020634920634920634,
        "yStart": 1.0892857142857142,
        "xEnd": -0.020634920634920634,
        "yEnd": 1.0863095238095237,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.020634920634920634,
        "yStart": 1.0863095238095237,
        "xEnd": -0.01904761904761905,
        "yEnd": 1.0863095238095237,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.01904761904761905,
        "yStart": 1.0863095238095237,
        "xEnd": -0.01746031746031746,
        "yEnd": 1.0833333333333333,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.01746031746031746,
        "yStart": 1.0833333333333333,
        "xEnd": -0.01746031746031746,
        "yEnd": 1.0833333333333333,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.01746031746031746,
        "yStart": 1.0833333333333333,
        "xEnd": -0.015873015873015872,
        "yEnd": 1.0773809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 1.0773809523809523,
        "xEnd": -0.015873015873015872,
        "yEnd": 1.0773809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.015873015873015872,
        "yStart": 1.0773809523809523,
        "xEnd": -0.014285714285714285,
        "yEnd": 1.0773809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.014285714285714285,
        "yStart": 1.0773809523809523,
        "xEnd": -0.012698412698412698,
        "yEnd": 1.0744047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.012698412698412698,
        "yStart": 1.0744047619047619,
        "xEnd": -0.012698412698412698,
        "yEnd": 1.068452380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.012698412698412698,
        "yStart": 1.068452380952381,
        "xEnd": -0.011111111111111112,
        "yEnd": 1.068452380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.011111111111111112,
        "yStart": 1.068452380952381,
        "xEnd": -0.009523809523809525,
        "yEnd": 1.0654761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.009523809523809525,
        "yStart": 1.0654761904761905,
        "xEnd": -0.009523809523809525,
        "yEnd": 1.0625,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.009523809523809525,
        "yStart": 1.0625,
        "xEnd": -0.007936507936507936,
        "yEnd": 1.0595238095238095,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.007936507936507936,
        "yStart": 1.0595238095238095,
        "xEnd": -0.004761904761904762,
        "yEnd": 1.056547619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": 1.056547619047619,
        "xEnd": -0.004761904761904762,
        "yEnd": 1.0535714285714286,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": 1.0535714285714286,
        "xEnd": -0.004761904761904762,
        "yEnd": 1.0535714285714286,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.004761904761904762,
        "yStart": 1.0535714285714286,
        "xEnd": -0.0031746031746031746,
        "yEnd": 1.0505952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 1.0505952380952381,
        "xEnd": -0.0031746031746031746,
        "yEnd": 1.0505952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 1.0505952380952381,
        "xEnd": -0.0031746031746031746,
        "yEnd": 1.0505952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.0031746031746031746,
        "yStart": 1.0505952380952381,
        "xEnd": -0.0015873015873015873,
        "yEnd": 1.0476190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 1.0476190476190477,
        "xEnd": -0.0015873015873015873,
        "yEnd": 1.0476190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 1.0476190476190477,
        "xEnd": -0.0015873015873015873,
        "yEnd": 1.0476190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 1.0476190476190477,
        "xEnd": -0.0015873015873015873,
        "yEnd": 1.0446428571428572,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": -0.0015873015873015873,
        "yStart": 1.0446428571428572,
        "xEnd": 0,
        "yEnd": 1.0446428571428572,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 21
    },
    {
        "xStart": 0.47619047619047616,
        "yStart": -0.10119047619047619,
        "xEnd": 0.47619047619047616,
        "yEnd": -0.10119047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.47619047619047616,
        "yStart": -0.10119047619047619,
        "xEnd": 0.47619047619047616,
        "yEnd": -0.10119047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.47619047619047616,
        "yStart": -0.10119047619047619,
        "xEnd": 0.47619047619047616,
        "yEnd": -0.10119047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.47619047619047616,
        "yStart": -0.10119047619047619,
        "xEnd": 0.47619047619047616,
        "yEnd": -0.10119047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.47619047619047616,
        "yStart": -0.10119047619047619,
        "xEnd": 0.4777777777777778,
        "yEnd": -0.10416666666666667,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.4777777777777778,
        "yStart": -0.10416666666666667,
        "xEnd": 0.4793650793650794,
        "yEnd": -0.10714285714285714,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.4793650793650794,
        "yStart": -0.10714285714285714,
        "xEnd": 0.48253968253968255,
        "yEnd": -0.1130952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.48253968253968255,
        "yStart": -0.1130952380952381,
        "xEnd": 0.48412698412698413,
        "yEnd": -0.11607142857142858,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.48412698412698413,
        "yStart": -0.11607142857142858,
        "xEnd": 0.4888888888888889,
        "yEnd": -0.125,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.4888888888888889,
        "yStart": -0.125,
        "xEnd": 0.49206349206349204,
        "yEnd": -0.13392857142857142,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.49206349206349204,
        "yStart": -0.13392857142857142,
        "xEnd": 0.4936507936507937,
        "yEnd": -0.13690476190476192,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.4936507936507937,
        "yStart": -0.13690476190476192,
        "xEnd": 0.49682539682539684,
        "yEnd": -0.14285714285714285,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.49682539682539684,
        "yStart": -0.14285714285714285,
        "xEnd": 0.5031746031746032,
        "yEnd": -0.15178571428571427,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.5031746031746032,
        "yStart": -0.15178571428571427,
        "xEnd": 0.5031746031746032,
        "yEnd": -0.15476190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.5031746031746032,
        "yStart": -0.15476190476190477,
        "xEnd": 0.5095238095238095,
        "yEnd": -0.1636904761904762,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.5095238095238095,
        "yStart": -0.1636904761904762,
        "xEnd": 0.5126984126984127,
        "yEnd": -0.16964285714285715,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.5126984126984127,
        "yStart": -0.16964285714285715,
        "xEnd": 0.5142857142857142,
        "yEnd": -0.17261904761904762,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.5142857142857142,
        "yStart": -0.17261904761904762,
        "xEnd": 0.5158730158730159,
        "yEnd": -0.17261904761904762,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.5158730158730159,
        "yStart": -0.17261904761904762,
        "xEnd": 0.5190476190476191,
        "yEnd": -0.17857142857142858,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.5190476190476191,
        "yStart": -0.17857142857142858,
        "xEnd": 0.5222222222222223,
        "yEnd": -0.18452380952380953,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.5222222222222223,
        "yStart": -0.18452380952380953,
        "xEnd": 0.5222222222222223,
        "yEnd": -0.18452380952380953,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.5222222222222223,
        "yStart": -0.18452380952380953,
        "xEnd": 0.5238095238095238,
        "yEnd": -0.18452380952380953,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 28
    },
    {
        "xStart": 0.5238095238095238,
        "yStart": -0.10416666666666667,
        "xEnd": 0.5238095238095238,
        "yEnd": -0.10416666666666667,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.5238095238095238,
        "yStart": -0.10416666666666667,
        "xEnd": 0.5238095238095238,
        "yEnd": -0.10416666666666667,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.5238095238095238,
        "yStart": -0.10416666666666667,
        "xEnd": 0.5222222222222223,
        "yEnd": -0.10416666666666667,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.5222222222222223,
        "yStart": -0.10416666666666667,
        "xEnd": 0.5222222222222223,
        "yEnd": -0.10416666666666667,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.5222222222222223,
        "yStart": -0.10416666666666667,
        "xEnd": 0.5174603174603175,
        "yEnd": -0.1130952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.5174603174603175,
        "yStart": -0.1130952380952381,
        "xEnd": 0.5158730158730159,
        "yEnd": -0.1130952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.5158730158730159,
        "yStart": -0.1130952380952381,
        "xEnd": 0.5126984126984127,
        "yEnd": -0.11607142857142858,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.5126984126984127,
        "yStart": -0.11607142857142858,
        "xEnd": 0.5095238095238095,
        "yEnd": -0.11904761904761904,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.5095238095238095,
        "yStart": -0.11904761904761904,
        "xEnd": 0.5095238095238095,
        "yEnd": -0.12202380952380952,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.5095238095238095,
        "yStart": -0.12202380952380952,
        "xEnd": 0.5063492063492063,
        "yEnd": -0.125,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.5063492063492063,
        "yStart": -0.125,
        "xEnd": 0.5031746031746032,
        "yEnd": -0.12797619047619047,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.5031746031746032,
        "yStart": -0.12797619047619047,
        "xEnd": 0.4984126984126984,
        "yEnd": -0.13392857142857142,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.4984126984126984,
        "yStart": -0.13392857142857142,
        "xEnd": 0.4984126984126984,
        "yEnd": -0.13392857142857142,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.4984126984126984,
        "yStart": -0.13392857142857142,
        "xEnd": 0.49682539682539684,
        "yEnd": -0.13690476190476192,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.49682539682539684,
        "yStart": -0.13690476190476192,
        "xEnd": 0.49523809523809526,
        "yEnd": -0.13988095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.49523809523809526,
        "yStart": -0.13988095238095238,
        "xEnd": 0.4936507936507937,
        "yEnd": -0.13988095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.4936507936507937,
        "yStart": -0.13988095238095238,
        "xEnd": 0.49206349206349204,
        "yEnd": -0.14583333333333334,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.49206349206349204,
        "yStart": -0.14583333333333334,
        "xEnd": 0.49047619047619045,
        "yEnd": -0.14583333333333334,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.49047619047619045,
        "yStart": -0.14583333333333334,
        "xEnd": 0.4888888888888889,
        "yEnd": -0.1488095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.4888888888888889,
        "yStart": -0.1488095238095238,
        "xEnd": 0.48253968253968255,
        "yEnd": -0.15773809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.48253968253968255,
        "yStart": -0.15773809523809523,
        "xEnd": 0.48095238095238096,
        "yEnd": -0.15773809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.48095238095238096,
        "yStart": -0.15773809523809523,
        "xEnd": 0.48095238095238096,
        "yEnd": -0.16071428571428573,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.48095238095238096,
        "yStart": -0.16071428571428573,
        "xEnd": 0.4777777777777778,
        "yEnd": -0.1636904761904762,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.4777777777777778,
        "yStart": -0.1636904761904762,
        "xEnd": 0.47619047619047616,
        "yEnd": -0.16666666666666666,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.47619047619047616,
        "yStart": -0.16666666666666666,
        "xEnd": 0.47619047619047616,
        "yEnd": -0.16666666666666666,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.47619047619047616,
        "yStart": -0.16666666666666666,
        "xEnd": 0.4714285714285714,
        "yEnd": -0.17559523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.4714285714285714,
        "yStart": -0.17559523809523808,
        "xEnd": 0.4714285714285714,
        "yEnd": -0.17559523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.4714285714285714,
        "yStart": -0.17559523809523808,
        "xEnd": 0.4714285714285714,
        "yEnd": -0.17559523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.4714285714285714,
        "yStart": -0.17559523809523808,
        "xEnd": 0.4714285714285714,
        "yEnd": -0.17559523809523808,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.4714285714285714,
        "yStart": -0.17559523809523808,
        "xEnd": 0.46984126984126984,
        "yEnd": -0.17857142857142858,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.46984126984126984,
        "yStart": -0.17857142857142858,
        "xEnd": 0.46984126984126984,
        "yEnd": -0.17857142857142858,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": 0.46984126984126984,
        "yStart": -0.17857142857142858,
        "xEnd": 0.46984126984126984,
        "yEnd": -0.17857142857142858,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 29
    },
    {
        "xStart": -0.10476190476190476,
        "yStart": 0.6904761904761905,
        "xEnd": -0.10476190476190476,
        "yEnd": 0.6904761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.10476190476190476,
        "yStart": 0.6904761904761905,
        "xEnd": -0.10476190476190476,
        "yEnd": 0.6875,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.10476190476190476,
        "yStart": 0.6875,
        "xEnd": -0.10476190476190476,
        "yEnd": 0.6875,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.10476190476190476,
        "yStart": 0.6875,
        "xEnd": -0.10476190476190476,
        "yEnd": 0.6875,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.10476190476190476,
        "yStart": 0.6875,
        "xEnd": -0.10476190476190476,
        "yEnd": 0.6875,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.10476190476190476,
        "yStart": 0.6875,
        "xEnd": -0.10317460317460317,
        "yEnd": 0.6845238095238095,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.10317460317460317,
        "yStart": 0.6845238095238095,
        "xEnd": -0.10158730158730159,
        "yEnd": 0.6785714285714286,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.10158730158730159,
        "yStart": 0.6785714285714286,
        "xEnd": -0.09365079365079365,
        "yEnd": 0.6577380952380952,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.09365079365079365,
        "yStart": 0.6577380952380952,
        "xEnd": -0.08888888888888889,
        "yEnd": 0.6488095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.08888888888888889,
        "yStart": 0.6488095238095238,
        "xEnd": -0.0873015873015873,
        "yEnd": 0.6428571428571429,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.0873015873015873,
        "yStart": 0.6428571428571429,
        "xEnd": -0.08412698412698413,
        "yEnd": 0.6339285714285714,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.08412698412698413,
        "yStart": 0.6339285714285714,
        "xEnd": -0.08253968253968254,
        "yEnd": 0.6339285714285714,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.08253968253968254,
        "yStart": 0.6339285714285714,
        "xEnd": -0.08253968253968254,
        "yEnd": 0.6309523809523809,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.08253968253968254,
        "yStart": 0.6309523809523809,
        "xEnd": -0.07936507936507936,
        "yEnd": 0.6220238095238095,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.07936507936507936,
        "yStart": 0.6220238095238095,
        "xEnd": -0.07777777777777778,
        "yEnd": 0.6190476190476191,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.07777777777777778,
        "yStart": 0.6190476190476191,
        "xEnd": -0.07777777777777778,
        "yEnd": 0.6160714285714286,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.07777777777777778,
        "yStart": 0.6160714285714286,
        "xEnd": -0.07777777777777778,
        "yEnd": 0.6160714285714286,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.07777777777777778,
        "yStart": 0.6160714285714286,
        "xEnd": -0.0761904761904762,
        "yEnd": 0.6130952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.0761904761904762,
        "yStart": 0.6130952380952381,
        "xEnd": -0.0761904761904762,
        "yEnd": 0.6130952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 30
    },
    {
        "xStart": -0.05555555555555555,
        "yStart": 0.6934523809523809,
        "xEnd": -0.05555555555555555,
        "yEnd": 0.6934523809523809,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.05555555555555555,
        "yStart": 0.6934523809523809,
        "xEnd": -0.05555555555555555,
        "yEnd": 0.6934523809523809,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.05555555555555555,
        "yStart": 0.6934523809523809,
        "xEnd": -0.05555555555555555,
        "yEnd": 0.6904761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.05555555555555555,
        "yStart": 0.6904761904761905,
        "xEnd": -0.05714285714285714,
        "yEnd": 0.6904761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.05714285714285714,
        "yStart": 0.6904761904761905,
        "xEnd": -0.05714285714285714,
        "yEnd": 0.6904761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.05714285714285714,
        "yStart": 0.6904761904761905,
        "xEnd": -0.05714285714285714,
        "yEnd": 0.6875,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.05714285714285714,
        "yStart": 0.6875,
        "xEnd": -0.05873015873015873,
        "yEnd": 0.6845238095238095,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.05873015873015873,
        "yStart": 0.6845238095238095,
        "xEnd": -0.05873015873015873,
        "yEnd": 0.6785714285714286,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.05873015873015873,
        "yStart": 0.6785714285714286,
        "xEnd": -0.05873015873015873,
        "yEnd": 0.6755952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.05873015873015873,
        "yStart": 0.6755952380952381,
        "xEnd": -0.06031746031746032,
        "yEnd": 0.6696428571428571,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.06031746031746032,
        "yStart": 0.6696428571428571,
        "xEnd": -0.06190476190476191,
        "yEnd": 0.6696428571428571,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.06190476190476191,
        "yStart": 0.6696428571428571,
        "xEnd": -0.06825396825396825,
        "yEnd": 0.6220238095238095,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.06825396825396825,
        "yStart": 0.6220238095238095,
        "xEnd": -0.06984126984126984,
        "yEnd": 0.6160714285714286,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.06984126984126984,
        "yStart": 0.6160714285714286,
        "xEnd": -0.07142857142857142,
        "yEnd": 0.6101190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.07142857142857142,
        "yStart": 0.6101190476190477,
        "xEnd": -0.07142857142857142,
        "yEnd": 0.6041666666666666,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.07142857142857142,
        "yStart": 0.6041666666666666,
        "xEnd": -0.07301587301587302,
        "yEnd": 0.5952380952380952,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.07301587301587302,
        "yStart": 0.5952380952380952,
        "xEnd": -0.07301587301587302,
        "yEnd": 0.5922619047619048,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.07301587301587302,
        "yStart": 0.5922619047619048,
        "xEnd": -0.0761904761904762,
        "yEnd": 0.5833333333333334,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.0761904761904762,
        "yStart": 0.5833333333333334,
        "xEnd": -0.0761904761904762,
        "yEnd": 0.5803571428571429,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.0761904761904762,
        "yStart": 0.5803571428571429,
        "xEnd": -0.0761904761904762,
        "yEnd": 0.5773809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.0761904761904762,
        "yStart": 0.5773809523809523,
        "xEnd": -0.07777777777777778,
        "yEnd": 0.5773809523809523,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.07777777777777778,
        "yStart": 0.5773809523809523,
        "xEnd": -0.07777777777777778,
        "yEnd": 0.5744047619047619,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.07777777777777778,
        "yStart": 0.5744047619047619,
        "xEnd": -0.07936507936507936,
        "yEnd": 0.5684523809523809,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.07936507936507936,
        "yStart": 0.5684523809523809,
        "xEnd": -0.07936507936507936,
        "yEnd": 0.5654761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.07936507936507936,
        "yStart": 0.5654761904761905,
        "xEnd": -0.07936507936507936,
        "yEnd": 0.5654761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.07936507936507936,
        "yStart": 0.5654761904761905,
        "xEnd": -0.08095238095238096,
        "yEnd": 0.5654761904761905,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.08095238095238096,
        "yStart": 0.5654761904761905,
        "xEnd": -0.08095238095238096,
        "yEnd": 0.5625,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.08095238095238096,
        "yStart": 0.5625,
        "xEnd": -0.08253968253968254,
        "yEnd": 0.5595238095238095,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.08253968253968254,
        "yStart": 0.5595238095238095,
        "xEnd": -0.08412698412698413,
        "yEnd": 0.5535714285714286,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.08412698412698413,
        "yStart": 0.5535714285714286,
        "xEnd": -0.08412698412698413,
        "yEnd": 0.5535714285714286,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.08412698412698413,
        "yStart": 0.5535714285714286,
        "xEnd": -0.08571428571428572,
        "yEnd": 0.5505952380952381,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.08571428571428572,
        "yStart": 0.5505952380952381,
        "xEnd": -0.08571428571428572,
        "yEnd": 0.5476190476190477,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.08571428571428572,
        "yStart": 0.5476190476190477,
        "xEnd": -0.08571428571428572,
        "yEnd": 0.5446428571428571,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.08571428571428572,
        "yStart": 0.5446428571428571,
        "xEnd": -0.0873015873015873,
        "yEnd": 0.5446428571428571,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.0873015873015873,
        "yStart": 0.5446428571428571,
        "xEnd": -0.0873015873015873,
        "yEnd": 0.5416666666666666,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.0873015873015873,
        "yStart": 0.5416666666666666,
        "xEnd": -0.0873015873015873,
        "yEnd": 0.5386904761904762,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.0873015873015873,
        "yStart": 0.5386904761904762,
        "xEnd": -0.08888888888888889,
        "yEnd": 0.5386904761904762,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.08888888888888889,
        "yStart": 0.5386904761904762,
        "xEnd": -0.08888888888888889,
        "yEnd": 0.5357142857142857,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.08888888888888889,
        "yStart": 0.5357142857142857,
        "xEnd": -0.08888888888888889,
        "yEnd": 0.5357142857142857,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.08888888888888889,
        "yStart": 0.5357142857142857,
        "xEnd": -0.08888888888888889,
        "yEnd": 0.5357142857142857,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.08888888888888889,
        "yStart": 0.5357142857142857,
        "xEnd": -0.08888888888888889,
        "yEnd": 0.5327380952380952,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.08888888888888889,
        "yStart": 0.5327380952380952,
        "xEnd": -0.08888888888888889,
        "yEnd": 0.5327380952380952,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.08888888888888889,
        "yStart": 0.5327380952380952,
        "xEnd": -0.09047619047619047,
        "yEnd": 0.5297619047619048,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.09047619047619047,
        "yStart": 0.5297619047619048,
        "xEnd": -0.09047619047619047,
        "yEnd": 0.5297619047619048,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.09047619047619047,
        "yStart": 0.5297619047619048,
        "xEnd": -0.09047619047619047,
        "yEnd": 0.5267857142857143,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.09047619047619047,
        "yStart": 0.5267857142857143,
        "xEnd": -0.09047619047619047,
        "yEnd": 0.5267857142857143,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.09047619047619047,
        "yStart": 0.5267857142857143,
        "xEnd": -0.09047619047619047,
        "yEnd": 0.5267857142857143,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    },
    {
        "xStart": -0.09047619047619047,
        "yStart": 0.5267857142857143,
        "xEnd": -0.09047619047619047,
        "yEnd": 0.5238095238095238,
        "thickness": 5,
        "color": "#636363",
        "sketchLineId": 31
    }
]