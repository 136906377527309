import { useEffect, useRef, useContext, useImperativeHandle, forwardRef, useState } from "react";
import { AppContext, TextBox } from './AppContext';
import { ShapeFilterDefinition } from './ShapeFilterDefinition';
import { Point2D, throwIfNull } from "./utils";
import { sampleAnnotationSegments } from "./SampleAnnotationSegments";
import { sampleMeasureSegments } from "./SampleMeasureSegments";
import { cloneObjectArray, cloneShapes } from "./CopyUtils";

const eraserBuffer = 0.05;
const selectionBuffer = 0.05;

// Define the type of methods to expose to the parent via ref
export interface SketchRef {
    clearCanvas: () => void;
    saveSketchAsPng: () => Promise<Blob | null>;
}

class MouseAndTouchEventParameters {
    canvasElement: HTMLCanvasElement;
    x: number;
    y: number;

    constructor(canvasElement: HTMLCanvasElement, x: number, y: number) {
        this.canvasElement = canvasElement;
        this.x = x;
        this.y = y;
    }

    public ConvertClientToOffsetCoordinates(): MouseAndTouchEventParameters {
        const rect = this.canvasElement.getBoundingClientRect();
    
        this.x -= rect.left + window.scrollX;
        this.y -= rect.top + window.scrollY;
    
        // Flip the y-axis
        this.y = this.canvasElement.height - this.y;
    
        return this;
    }
}

let xStart: number, xEnd: number, yStart: number, yEnd: number;
let paint = false;
let ctx: CanvasRenderingContext2D | null = null;
let lineThickness: number = 2;
let currentSketchId = 0;

const drawingAreaWidthPaddingPercent = 0.15;
const drawingAreaHeightPaddingPercent = 0.15;

// LineSegment interface to store normalized coordinates
export interface LineSegment {
    xStart: number; // normalized to canvas width
    yStart: number; // normalized to canvas height
    xEnd: number;   // normalized to canvas width
    yEnd: number;   // normalized to canvas height
    thickness: number;
    color: string;
    sketchLineId: number;
}

// Function to normalize a point by the canvas dimensions
export function normalizePoint(x: number, y: number, canvasWidth: number, canvasHeight: number) {
    const widthPadding = drawingAreaWidthPaddingPercent * canvasWidth;
    const heightPadding = drawingAreaHeightPaddingPercent * canvasHeight;
    const drawingWidth = canvasWidth - 2*widthPadding;
    const drawingHeight = canvasHeight - 2*heightPadding;

    return {
        x: (x - widthPadding) / drawingWidth,
        y: (y - heightPadding) / drawingHeight,
    };
}

// Function to denormalize a point based on the current canvas size
export function denormalizePoint(x: number, y: number, canvasWidth: number, canvasHeight: number) {
    const widthPadding = drawingAreaWidthPaddingPercent * canvasWidth;
    const heightPadding = drawingAreaHeightPaddingPercent * canvasHeight;
    const drawingWidth = canvasWidth - 2*widthPadding;
    const drawingHeight = canvasHeight - 2*heightPadding;
    
    return {
        x: (x * drawingWidth) + widthPadding,
        y: (y * drawingHeight) + heightPadding,
    };
}

type SketchProps = {
    shapeFilterIndex: number;
    isViewOnly: boolean;
    isAddingText?: boolean;
    canvasWidth: number,
    canvasHeight: number,
}

export const Sketch = forwardRef<SketchRef, SketchProps>(({shapeFilterIndex, isViewOnly, isAddingText, canvasWidth, canvasHeight}, ref) => {
    const {shapeFilterLibrary, updateShapeInLibrary, updateAnnotationInLibrary, currentColor, currentMeasure, listOfMeasures, eraserEnabled, isEditingExistingSketch, editingSketch, setSketchBlob, updateTextBoxesInLibrary, setIsAddingText } = throwIfNull(useContext(AppContext));
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const linearizeButtonRef = useRef<HTMLButtonElement | null>(null);
    const shapeFilterLibraryRef = useRef(shapeFilterLibrary);
    const currentColorRef = useRef(currentColor);
    const currentMeasureRef = useRef(currentMeasure);
    const shapeFilterIndexRef = useRef(shapeFilterIndex);
    const updateShapeInLibraryRef = useRef(updateShapeInLibrary);
    const updateAnnotationInLibraryRef = useRef(updateAnnotationInLibrary);
    const updateTextBoxesInLibraryRef = useRef(updateTextBoxesInLibrary);
    const eraserEnabledRef = useRef(eraserEnabled);
    const isEditingExistingSketchRef = useRef(isEditingExistingSketch);
    const editingSketchRef = useRef(editingSketch);
    const isAddingTextRef = useRef(isAddingText);
    const shapeFilterItemRef = useRef(!isViewOnly && isEditingExistingSketchRef.current && editingSketchRef.current ? 
            editingSketchRef.current : shapeFilterLibraryRef.current[shapeFilterIndexRef.current]);

    // Sync the ref with the current shapeFilterLibraryRef.current
    useEffect(() => {
        shapeFilterLibraryRef.current = shapeFilterLibrary;
    }, [shapeFilterLibrary]);

    // Sync the ref with the current currentColorRef.currentRef.current
    useEffect(() => {
        currentColorRef.current = currentColor;
    }, [currentColor]);

    // Sync the ref with the current currentMeasureRef.currentRef.current
    useEffect(() => {
        currentMeasureRef.current = currentMeasure;
    }, [currentMeasure]);

    // Sync the ref with the current shapeFilterLibraryRef.current
    useEffect(() => {
        updateShapeInLibraryRef.current = updateShapeInLibrary;
    }, [updateShapeInLibrary]);

    // Sync the ref with the current updateAnnotationInLibraryRef.currentRef.current
    useEffect(() => {
        updateAnnotationInLibraryRef.current = updateAnnotationInLibrary;
    }, [updateAnnotationInLibrary]);

    // Sync the ref with the current updateTextBoxesInLibraryRef.currentRef.current
    useEffect(() => {
        updateTextBoxesInLibraryRef.current = updateTextBoxesInLibrary;
    }, [updateTextBoxesInLibrary])

    // Sync the ref with the current eraserEnabledRef.currentRef.current
    useEffect(() => {
        eraserEnabledRef.current = eraserEnabled;
    }, [eraserEnabled]);

    // Sync the ref with the current isEditingExistingSketchRef.currentRef.current
    useEffect(() => {
        isEditingExistingSketchRef.current = isEditingExistingSketch;
    }, [isEditingExistingSketch])

    // Sync the ref with the current editingSketchRef.currentRef.current
    useEffect(() => {
        editingSketchRef.current = {
            measureShapes: cloneShapes(editingSketch.measureShapes),
            annotationShapes: cloneShapes(editingSketch.annotationShapes),
            textBoxes: cloneObjectArray(editingSketch.textBoxes)
        };
    }, [editingSketch]);

    useEffect(() => {
        isAddingTextRef.current = isAddingText;
    }, [isAddingText])

    useEffect(() => {
        const isEditingMode = !isViewOnly && isEditingExistingSketchRef.current && editingSketchRef.current;
        shapeFilterItemRef.current = isEditingMode ? 
            editingSketchRef.current : shapeFilterLibraryRef.current[shapeFilterIndexRef.current];
    }, [isEditingExistingSketchRef.current, editingSketchRef.current, shapeFilterLibraryRef.current, shapeFilterIndexRef.current, isAddingTextRef.current])


    const [displayPreview, setDisplayPreview] = useState<boolean>(
        !isViewOnly && shapeFilterItemRef.current.measureShapes.size === 0 && 
        shapeFilterItemRef.current.annotationShapes.size === 0 && 
        shapeFilterItemRef.current.textBoxes.length === 0);

    // Ref to track added event listeners
    const listenersRef = useRef<any>({
        mousedown: null,
        mouseup: null,
        mousemove: null,
        mouseLeave: null,
        touchstart: null,
        touchmove: null,
        touchend: null,
        keydown: null,
    });

    const fontSize = isViewOnly ? 6 : 18;

    useEffect(() => {
        const canvas = canvasRef.current;
        
        // Helper function to add an event listener and track it
        const addListener = (eventType: any, handleEvent: any, isDocumentEventListener: boolean = false) => {
            const canvas = canvasRef.current as HTMLCanvasElement;

            if (listenersRef.current[eventType]) return; // Prevent duplicate listeners
            listenersRef.current[eventType] = handleEvent;
            isDocumentEventListener ? document.getElementsByClassName("dialog-wrapper")[0].addEventListener(eventType, handleEvent) : canvas.addEventListener(eventType, handleEvent);
        };
    
        const removeListener = (eventType: string) => {
            const canvas = canvasRef.current as HTMLCanvasElement;

            if (listenersRef.current[eventType]) {
                canvas.removeEventListener(eventType, listenersRef.current[eventType]);
                listenersRef.current[eventType] = null;
            }
        };

        if (canvas && shapeFilterItemRef.current) {
            // Initialize canvas context
            ctx = canvas.getContext("2d");
            if (ctx) {
                ctx.lineJoin = "round";
                ctx.lineCap = "round";
            } else {
                console.error("Canvas context is not available.");
            }

            if (!isViewOnly) {
                // Bind each event separately to avoid TypeScript type issues
                addListener("mousedown", handleMouseEvent);
                addListener("mousemove", handleMouseEvent);
                addListener("mouseup", handleMouseEvent);
                addListener("mouseleave", handleMouseEvent);
                addListener("touchstart", handleTouchEvent);
                addListener("touchmove", handleTouchEvent);
                addListener("touchend", handleTouchEvent);
                addListener("keydown", handleKeyDownEvent, true);
            }
        } else {
            if (!isViewOnly) {
                // Bind each event separately to avoid TypeScript type issues
                removeListener("mousedown");
                removeListener("mousemove");
                removeListener("mouseup");
                removeListener("mouseleave");
                removeListener("touchstart");
                removeListener("touchmove");
                removeListener("touchend");
                removeListener("keydown");
            }
        }

        // Handle different events with type-safe methods
        function handleMouseEvent(e: MouseEvent) {    
            if (!ctx) return;
    
            const mouseParams = new MouseAndTouchEventParameters(e.currentTarget as HTMLCanvasElement, e.offsetX,e.offsetY);
            switch (e.type) {
                case "mousedown":
                    startDrawing(mouseParams);
                    break;
                case "mousemove":
                    if (paint) {
                        updateDrawing(new MouseAndTouchEventParameters(e.currentTarget as HTMLCanvasElement,
                                                                       e.offsetX,
                                                                       e.offsetY));
                    }
                    break;
                case "mouseup":
                case "mouseleave":
                    paint = false;
                    currentSketchId++; // move on to next sketch.
                    break;
            }
        }
    
        // Handle different events with type-safe methods
        function handleTouchEvent(e: TouchEvent) {    
            if (!ctx) return;
    
            // Touch events don't have 'offsetX' and 'offsetY' like mouse events, so we have to do the math ourselves (below).
            switch (e.type) {
                case "touchstart":
                    e.preventDefault();
                    startDrawing(new MouseAndTouchEventParameters(e.currentTarget as HTMLCanvasElement,
                                                                    e.touches[0].clientX,
                                                                    e.touches[0].clientY).ConvertClientToOffsetCoordinates());
                    break;
                case "touchmove":
                    if (paint) {
                        updateDrawing(new MouseAndTouchEventParameters(e.currentTarget as HTMLCanvasElement,
                                                                        e.touches[0].clientX,
                                                                        e.touches[0].clientY).ConvertClientToOffsetCoordinates());
                    }
                    break;
                case "touchend":
                    paint = false;
                    currentSketchId++; // move on to next sketch.
                    break;
            }
        }

        // Add a new text box
        function addTextBox(normalizedX: number, normalizedY: number) {
            // ensure all other text boxes are inActive
            let textBoxes: TextBox[] = cloneObjectArray(shapeFilterItemRef.current.textBoxes);
            textBoxes.forEach(textBox => textBox.isActive = false);

            const textBox: TextBox = {
                x: normalizedX,
                y: normalizedY,
                text: '',
                isActive: true,
            };
            updateTextBoxesInLibraryRef.current([...textBoxes, textBox],  shapeFilterIndexRef.current)
            setIsAddingText(false);
        }

        // Handle typing - if there is an active text box, update its text
        function handleKeyDownEvent(e: KeyboardEvent) {
            if (!ctx) return;
            let newTextBoxes: TextBox[] = cloneObjectArray(shapeFilterItemRef.current.textBoxes);
            const activeIdx = newTextBoxes.findIndex(box => box.isActive);
            if (activeIdx !== -1) {
                const key = e.key;
                if (key === 'Backspace') {
                    newTextBoxes[activeIdx].text = newTextBoxes[activeIdx].text.slice(0,-1);
                    updateTextBoxesInLibraryRef.current(newTextBoxes,  shapeFilterIndexRef.current)
                } else {
                    let addedChar = '';
                    switch(e.key) {
                        case 'Enter':
                            addedChar = '\n';
                            break;
                        case 'Shift':
                            break;
                        default:
                            addedChar = key;
                            break;
                    }
                    newTextBoxes[activeIdx].text = newTextBoxes[activeIdx].text + addedChar;
                    updateTextBoxesInLibraryRef.current(newTextBoxes, shapeFilterIndexRef.current)
                }
            }
        }

        // Start drawing for mouse events, storing normalized coordinates
        function startDrawing(params: MouseAndTouchEventParameters) {
            setDisplayPreview(false);
            const { x, y } = normalizePoint(params.x, params.y, params.canvasElement.width, params.canvasElement.height);
            
            if (isAddingTextRef.current) {
                addTextBox(x, y);
            } else {
                xStart = x;
                yStart = y;
                xEnd = x;
                yEnd = y;
                paint = true;
                if (!eraserEnabledRef.current && !currentColorRef.current) {
                    enableTextBox(params);
                } else {
                    updateLineSegments("mousedown", params.canvasElement);
                }
            }
        }

        // Update drawing for mouse move events with normalized coordinates
        function updateDrawing(params: MouseAndTouchEventParameters) {
            const { x, y } = normalizePoint(params.x, params.y, params.canvasElement.width, params.canvasElement.height);
            xEnd = x;
            yEnd = y;

            if (ctx) ctx.lineWidth = lineThickness;
            
            if (eraserEnabledRef.current) {
                // eraser
                eraseLineSegments();
                eraseText();
            } else if (currentColorRef.current){
                // drawing
                updateLineSegments("mousemove", params.canvasElement);
            } else {
                // moving text boxes around
                updateTextBoxes();
            }

            // Update start points for the next segment
            xStart = xEnd;
            yStart = yEnd;
        }

        // Returns true if the line from {x1,y1} to {x2,y2} is within eraserBuffer distance from the eraser center {eraserX,eraserY}
        function lineIsWithinEraserBufferDistance(eraserX: number, eraserY: number, x1: number, y1: number, x2: number, y2: number): boolean {
            var A = eraserX - x1;
            var B = eraserY - y1;
            var C = x2 - x1;
            var D = y2 - y1;
          
            var dot = A * C + B * D;
            var len_sq = C * C + D * D;
            var param = -1;
            if (len_sq !== 0) //in case of 0 length line
                param = dot / len_sq;
          
            var xx, yy;
          
            if (param < 0) {
              xx = x1;
              yy = y1;
            }
            else if (param > 1) {
              xx = x2;
              yy = y2;
            }
            else {
              xx = x1 + param * C;
              yy = y1 + param * D;
            }
          
            var dx = eraserX - xx;
            var dy = eraserY - yy;
            return Math.sqrt(dx * dx + dy * dy) < eraserBuffer;
        }

        // Removes erased line segments from the given mapOfSegments 
        function setFilteredLineSegments(mapOfSegments: Map<string,ShapeFilterDefinition>) {
            mapOfSegments.forEach((value, key) => {
                const originalLineSegments = value._lineSegments;
                if (originalLineSegments.length === 0) return [];

                value._lineSegments = originalLineSegments.filter(segment => {
                    return !(
                        lineIsWithinEraserBufferDistance(xStart, yStart, segment.xStart, segment.yStart, segment.xEnd, segment.yEnd) ||
                        lineIsWithinEraserBufferDistance(xEnd, yEnd, segment.xStart, segment.yStart, segment.xEnd, segment.yEnd)
                    );
                });
            });
        }

        // Erase line segments within eraserBuffer distance of the eraser
        function eraseLineSegments() {
            if (!ctx) return;

            // measures
            let measuresShapeFilters:Map<string,ShapeFilterDefinition> = shapeFilterItemRef.current.measureShapes;
            setFilteredLineSegments(measuresShapeFilters);
            let newCurrentMeasuresShapeFilter:ShapeFilterDefinition = measuresShapeFilters.get(currentMeasureRef.current) ?? new ShapeFilterDefinition(25, 0.35);
            updateShapeInLibraryRef.current(newCurrentMeasuresShapeFilter, shapeFilterIndexRef.current, currentMeasureRef.current);
            
            // annotations
            let annotationsShapeFilters:Map<string,ShapeFilterDefinition> = shapeFilterItemRef.current.annotationShapes;
            setFilteredLineSegments(annotationsShapeFilters);
            let newCurrentAnnotationsShapeFilter:ShapeFilterDefinition = annotationsShapeFilters.get(currentColorRef.current) ?? new ShapeFilterDefinition(25, 0.35);
            updateAnnotationInLibraryRef.current(newCurrentAnnotationsShapeFilter, shapeFilterIndexRef.current, currentColorRef.current);
        }

        // returns the normalized points for the four corners of the given text box
        function getNormalizedTextBoxPoints(textBox: TextBox) {
            if (!ctx) return;
            const drawingPoint = denormalizePoint(textBox.x, textBox.y, canvasWidth, canvasHeight);
            const textWidth = ctx.measureText(textBox.text).width;
            const textHeight = fontSize * 1.2;

            const topLeft = {x: textBox.x, y: textBox.y}
            const bottomRight = normalizePoint(drawingPoint.x + textWidth, drawingPoint.y - textHeight, canvasWidth, canvasHeight);
            const topRight = normalizePoint(drawingPoint.x + textWidth, drawingPoint.y, canvasWidth, canvasHeight);
            const bottomLeft = normalizePoint(drawingPoint.x, drawingPoint.y - textHeight, canvasWidth, canvasHeight);

            return {topLeft, bottomRight, topRight, bottomLeft};
        }
        
        // Erase text within eraserBuffer distance of the eraser
        function eraseText() {
            const newTextBoxes = [...shapeFilterItemRef.current.textBoxes].filter(textBox => {
                if (!ctx) return false;
                const {topLeft, bottomRight, topRight, bottomLeft} = {...getNormalizedTextBoxPoints(textBox)};
            
                if (topLeft && bottomRight && topRight && bottomLeft) {
                    // keep text box if its diagonals are outside the eraser buffer distance
                    return !(lineIsWithinEraserBufferDistance(xStart, yStart, topLeft.x, topLeft.y, bottomRight.x, bottomRight.y) ||
                    lineIsWithinEraserBufferDistance(xStart, yStart, topRight.x, topRight.y, bottomLeft.x, bottomLeft.y));
                }
                return false;
            });

            updateTextBoxesInLibraryRef.current(newTextBoxes, shapeFilterIndexRef.current);
        }

        // If there is a text box within the MouseDown point, enable it
        function enableTextBox(params: MouseAndTouchEventParameters) {
            const newTextBoxes = cloneObjectArray(shapeFilterItemRef.current.textBoxes);
            const clickedTextBoxIdx = newTextBoxes.findIndex((textBox: TextBox) => {
                if (!ctx) return true;
                const {topLeft, bottomRight, topRight, bottomLeft} = {...getNormalizedTextBoxPoints(textBox)};
                const { x, y } = normalizePoint(params.x, params.y, params.canvasElement.width, params.canvasElement.height);

                // return true if click is within selection buffer
                return topLeft && bottomRight && topRight && bottomLeft && 
                    x >= (topLeft.x - selectionBuffer) && x <= (topRight.x + selectionBuffer) && y <= (topLeft.y + selectionBuffer) && y >= (bottomLeft.y - selectionBuffer);
            });
            newTextBoxes.forEach((textBox: TextBox, index: number) => {
                if (index === clickedTextBoxIdx) {
                    textBox.isActive = true;
                } else {
                    textBox.isActive = false;
                }
            })
            updateTextBoxesInLibraryRef.current(newTextBoxes, shapeFilterIndexRef.current);
        }

        // Update text boxes if moving them around on the canvas
        function updateTextBoxes() {
            const newTextBoxes = shapeFilterItemRef.current.textBoxes;
            // find the text box that was moved
            const draggedTextBoxIdx = newTextBoxes.findIndex(textBox => {
                if (!ctx) return true;
                const {topLeft, bottomRight, topRight, bottomLeft} = {...getNormalizedTextBoxPoints(textBox)};

                // return true if start point is within selection buffer
                return topLeft && bottomRight && topRight && bottomLeft && 
                    xStart >= (topLeft.x - selectionBuffer) && xStart <= (topRight.x + selectionBuffer) && yStart <= (topLeft.y + selectionBuffer) && yStart >= (bottomLeft.y - selectionBuffer);
            });
            // if a text box was moved, move it
            if (draggedTextBoxIdx !== -1) {
                newTextBoxes.forEach((textBox, index) => {
                    if (!ctx) return;
                    const {topLeft, topRight} = {...getNormalizedTextBoxPoints(textBox)};
                    if (topLeft && topRight && index === draggedTextBoxIdx) {
                        const normalizedTextWidth = topRight.x - topLeft.x;
                        textBox.isActive = true;
                        textBox.x = xEnd - normalizedTextWidth/2;
                        textBox.y = yEnd;
                        return;
                    } else {
                        textBox.isActive = false;
                    }
                })
            }
            updateTextBoxesInLibraryRef.current(newTextBoxes, shapeFilterIndexRef.current);
        }
 
        // Draw function with denormalization to render points on the canvas
        function updateLineSegments(event: string, canvas: HTMLCanvasElement) {
            // Add the new segment to the array (stored in normalized format)
            const lineSegment = {
                xStart,
                yStart,
                xEnd,
                yEnd,
                thickness: lineThickness,
                color: currentColorRef.current,
                sketchLineId: currentSketchId
            };

            // update library
            if (isDrawingMeasure(currentColorRef.current)) {
                let currentShapeFilter:ShapeFilterDefinition = shapeFilterItemRef.current.measureShapes.get(currentMeasureRef.current) ?? new ShapeFilterDefinition(25, 0.35);
                currentShapeFilter._lineSegments = [...currentShapeFilter._lineSegments, lineSegment];
                updateShapeInLibraryRef.current(currentShapeFilter, shapeFilterIndexRef.current, currentMeasureRef.current);
            } else {
                let currentShapeFilter:ShapeFilterDefinition = shapeFilterItemRef.current.annotationShapes.get(currentColorRef.current) ?? new ShapeFilterDefinition(25, 0.35);
                currentShapeFilter._lineSegments = [...currentShapeFilter._lineSegments, lineSegment];
                updateAnnotationInLibraryRef.current(currentShapeFilter, shapeFilterIndexRef.current, currentColorRef.current);
            }

            // Update start points for the next segment
            xStart = xEnd;
            yStart = yEnd;
        }
        const isDrawingMeasure = (color: string) => {
            return listOfMeasures.find(measure => measure.color === color);
        }
    }, [shapeFilterLibraryRef.current, 
        shapeFilterIndexRef.current, 
        currentColorRef.current, 
        currentMeasureRef.current, 
        eraserEnabledRef.current, 
        isAddingTextRef.current, 
        shapeFilterItemRef.current, 
        updateAnnotationInLibraryRef.current,
        updateShapeInLibraryRef.current,
        updateTextBoxesInLibraryRef.current]);
    
    // Draw a circle with center {x,y} and the given radius and color
    const drawCircle = (ctx: any, x: number, y: number, radius: number, color: string) => {
        ctx.beginPath();
        ctx.arc(x, y, radius, 0, Math.PI * 2);
        ctx.fillStyle = color;
        ctx.fill();
    };

    /*
    // draw four circles at the corners of the active area.
    if (ctx.canvas.width > 500) {
        const offsetX = canvas.width * drawingAreaWidthPaddingPercent;
        const offsetY = canvas.height * drawingAreaHeightPaddingPercent;


        const circleRadius = 2;
        const circleColor = "rgba(211, 211, 211, 0.8)";
        // Top-left corner
        drawCircle(offsetX, offsetY, circleRadius, circleColor);
        // Top-right corner
        drawCircle(canvas.width - offsetX, offsetY, circleRadius, circleColor);
        // Bottom-left corner
        drawCircle(offsetX, canvas.height - offsetY, circleRadius, circleColor);
        // Bottom-right corner
        drawCircle(canvas.width - offsetX, canvas.height - offsetY, circleRadius, circleColor);
    }*/

    // Draw the given line segments
    const drawLine = (ctx: any, line: LineSegment) => {
        // Denormalize coordinates for actual drawing
        const startPoint = denormalizePoint(line.xStart, line.yStart, canvasWidth, canvasHeight);
        const endPoint = denormalizePoint(line.xEnd, line.yEnd, canvasWidth, canvasHeight);

        if (!isViewOnly) {
            line.thickness = 5;
        } else {
            line.thickness = 2;
        }
        // Draw the line
        ctx.beginPath();
        ctx.moveTo(startPoint.x, startPoint.y);
        ctx.lineTo(endPoint.x, endPoint.y);
        ctx.lineWidth = line.thickness;
        ctx.strokeStyle = line.color
        ctx.stroke();
    };

    useEffect(() =>  {
        const draw = () => {
            const canvas = canvasRef.current as HTMLCanvasElement;
            if (canvas && shapeFilterItemRef.current) {
                const ctx = canvas.getContext("2d");
                if (!ctx) {
                    console.error("Canvas context is not available.");
                    return;
                }

                // clear canvas to start from scratch
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

                // draw an example of a sketch. Once the user starts drawing this will disappear
                if (displayPreview) {
                    sampleMeasureSegments.forEach(lineSegment => drawLine(ctx, lineSegment));
                    sampleAnnotationSegments.forEach(lineSegment => drawLine(ctx, lineSegment));
                    
                    // add slightly opaque layer on top so lines aren't so dark
                    const fillStyle = ctx.fillStyle;
                    ctx.fillStyle = "rgba(255,255,255,0.5)";
                    ctx.fillRect(0,0,canvasWidth,canvasHeight);
                    ctx.fillStyle = fillStyle;
                }
                
                // draw guiding dots in the background of the sketching area
                if (!isViewOnly) {
                    const distance = 50;
                    const circleColor = "rgba(0, 0, 0, 0.1)";
                    for (let w = 25; w < canvasWidth; w += distance ){
                        for (let h = 15; h < canvasHeight; h += distance) {
                            drawCircle(ctx, w, h, 2, circleColor);
                        }
                    }
                };

                // add text
                const writeText = (textBox: TextBox) => {
                    const drawingPoint = denormalizePoint(textBox.x, textBox.y, canvas.width, canvas.height);
                    ctx.font = fontSize + "px Arial"
                    ctx.textAlign = 'left';
                    ctx.textBaseline = 'top';
                    ctx.fillStyle = 'black';  // a color name or by using rgb/rgba/hex values
                    ctx.fillText(textBox.text, drawingPoint.x, -1 * drawingPoint.y); // text and position, y position is negative due to scaling
                     
                    // add box around the active text box in the dialog
                    if (textBox.isActive) {
                        const textWidth = ctx.measureText(textBox.text).width;
                        const textHeight = fontSize * 1.2;
                        
                        if (!isViewOnly) {
                            ctx.strokeStyle = 'blue';
                            ctx.lineWidth = 2;
                            ctx.strokeRect(drawingPoint.x, -1 * drawingPoint.y, textWidth, textHeight);
                        }
                    }
                }

                shapeFilterItemRef.current.measureShapes.forEach(shape => {
                    shape.GetLineSegments().forEach(lineSegment => drawLine(ctx, lineSegment));
                });
                shapeFilterItemRef.current.annotationShapes.forEach(shape => {
                    shape.GetLineSegments().forEach(lineSegment => drawLine(ctx, lineSegment));
                });

                let textBoxes: TextBox[] = shapeFilterItemRef.current.textBoxes;
                // scale to make sure text is in the correct direction
                ctx.scale(1, -1);
                textBoxes.forEach(textBox => {
                    writeText(textBox)
                });
                // restore scale
                ctx.scale(1,-1);
            }
        }

        draw();
    }, [shapeFilterLibraryRef.current,
        shapeFilterIndexRef.current,
        isEditingExistingSketchRef.current,
        editingSketchRef.current,
        displayPreview,
        shapeFilterItemRef.current]);

    useEffect(() => {
        if (linearizeButtonRef) {
            linearizeButtonRef.current?.addEventListener("click", handleLinearizeButtonClick)
        }
    }, [])
    
    // Add event listener for the Linearize button
    const handleLinearizeButtonClick = () => {
        console.log("Linearize button clicked."); // Debug statement to confirm button click
        let measureShapes = shapeFilterItemRef.current.measureShapes;
        measureShapes.forEach((shape: ShapeFilterDefinition) => {
            const linearizationPoints = linearizeSketches(shape._lineSegments);
            console.log(linearizationPoints);
            drawLinearization(linearizationPoints);
        })
    }

    function drawLinearization(segments: Point2D[][]) {
        const canvas = canvasRef.current as HTMLCanvasElement;
        if (canvas) {
            const ctx = canvas.getContext("2d");
            if (!ctx) {
                console.error("Canvas context is not available.");
                return;
            }

            console.log('segments');
            console.log(segments);

            segments.forEach(segmentPoints => {
                const denormalizedPoints = segmentPoints.map(point => {
                    return denormalizePoint(point.x, point.y, canvas.width, canvas.height);
                });

                // Draw simplified line overlay
                ctx!.lineWidth = 10; 
                ctx!.strokeStyle = "rgba(173, 216, 230, 0.7)"; 

                ctx!.beginPath();
                ctx!.moveTo(denormalizedPoints[0].x, denormalizedPoints[0].y);
                for (let i = 1; i < denormalizedPoints.length; i++) {
                    ctx!.lineTo(denormalizedPoints[i].x, denormalizedPoints[i].y);
                }
                ctx!.stroke();
            })

            // Restore the original sketch color
            ctx.strokeStyle = currentColorRef.current ?? "#000000";
        }
    }

    // Function to save the sketch as a PNG
    const saveSketchAsPng = async (): Promise<Blob | null> => {
        const canvas = canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext("2d");
            if (ctx) {
                // Create a temporary canvas to avoid affecting the original canvas
                const tempCanvas = document.createElement("canvas");
                const tempCtx = tempCanvas.getContext("2d");

                // Set the dimensions of the temporary canvas
                tempCanvas.width = canvas.width;
                tempCanvas.height = canvas.height;

                // Copy the current canvas content, applying transformations
                tempCtx?.save(); // Save the current context state
                tempCtx?.scale(1, -1); // Flip vertically
                tempCtx?.drawImage(canvas, 0, -canvas.height); // Invert y-axis while drawing
                tempCtx?.restore(); // Restore the context to the saved state

                // Generate a PNG Blob from the temporary canvas
                return new Promise<Blob | null>((resolve, reject) => {
                    tempCanvas.toBlob(
                        (blob) => {
                            if (blob) {
                                // Trigger download
                                //const link = document.createElement("a");
                                //link.href = URL.createObjectURL(blob);
                                //link.download = "sketch.png";
                                //link.click();

                                resolve(blob);
                                setSketchBlob(blob);
                                console.log(blob);
                            } else {
                                console.error("Failed to create image blob.");
                                reject(null);
                            }
                        },
                        "image/png"
                    );
                });
            } else {
                console.error("Failed to get canvas context.");
                return null;
            }
        } else {
            console.error("Canvas element not found.");
            return null;
        }
    };

    // Function to clear the canvas
    const clearCanvas = () => {
        if (!ctx) {
            console.error("Canvas context is not available.");
            return;
        }

        // Clear the canvas
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    }

    // Use useImperativeHandle to expose clearCanvas to the parent component
    useImperativeHandle(ref, () => ({
        clearCanvas,
        saveSketchAsPng
    }));

    return (
        <div className="sketch-canvas">
            <canvas ref={canvasRef} id="canvas" width={canvasWidth} height={canvasHeight}></canvas>
            <div id="sketch-controls">
            {/*<button onClick={saveSketchAsPng}>Save as PNG</button>*/}
            </div>
        </div>
    );
});

function douglasPeucker(points: Point2D[], tolerance: number): Point2D[] {
    if (points.length < 3) return points;

    const start = points[0];
    const end = points[points.length - 1];
    let maxDistance = 0;
    let index = 0;

    for (let i = 1; i < points.length - 1; i++) {
        const distance = perpendicularDistance(points[i], start, end);
        if (distance > maxDistance) {
            index = i;
            maxDistance = distance;
        }
    }

    if (maxDistance > tolerance) {
        const left = douglasPeucker(points.slice(0, index + 1), tolerance);
        const right = douglasPeucker(points.slice(index), tolerance);
        return [...left.slice(0, -1), ...right];
    } else {
        return [start, end];
    }
}

function perpendicularDistance(point: { x: number; y: number }, lineStart: { x: number; y: number }, lineEnd: { x: number; y: number }) {
    const num = Math.abs(
        (lineEnd.y - lineStart.y) * point.x -
        (lineEnd.x - lineStart.x) * point.y +
        lineEnd.x * lineStart.y -
        lineEnd.y * lineStart.x
    );
    const den = Math.sqrt(
        (lineEnd.y - lineStart.y) ** 2 +
        (lineEnd.x - lineStart.x) ** 2
    );
    return num / den;
}


function simplifyLineSegments(lineSegments: LineSegment[], epsilon:number): LineSegment[][] {
    const lineSegmentsBySketchLineId = new Map<number, LineSegment[]>();

    // Group line segments by sketchLineId
    for (const segment of lineSegments) {
        const key = segment.sketchLineId;
        if (!lineSegmentsBySketchLineId.has(key)) {
            lineSegmentsBySketchLineId.set(key, []);
        }
        lineSegmentsBySketchLineId.get(key)!.push(segment);
    }

    const simplifiedLineSegments: LineSegment[][] = [];

    // Process each group
    lineSegmentsBySketchLineId.forEach((segments: LineSegment[], sketchLineId: number) => {
        const points: Point2D[] = [];

        // Build the polyline from line segments
        for (const segment of segments) {
            const startPoint: Point2D = { x: segment.xStart, y: segment.yStart };
            const endPoint: Point2D = { x: segment.xEnd, y: segment.yEnd };

            // Avoid duplicate consecutive points
            if (
                points.length === 0 ||
                points[points.length - 1].x !== startPoint.x ||
                points[points.length - 1].y !== startPoint.y
            ) {
                points.push(startPoint);
            }

            if (startPoint.x !== endPoint.x || startPoint.y !== endPoint.y) {
                points.push(endPoint);
            }
        }

        // Apply Douglas-Peucker algorithm to simplify points
        const simplifiedPoints = douglasPeucker(points, epsilon);

        // Reconstruct simplified line segments
        const simplifiedSegments: LineSegment[] = [];
        for (let i = 0; i < simplifiedPoints.length - 1; i++) {
            const start = simplifiedPoints[i];
            const end = simplifiedPoints[i + 1];
            const originalSegment = segments[0]; // Assumes uniform properties

            simplifiedSegments.push({
                xStart: start.x,
                yStart: start.y,
                xEnd: end.x,
                yEnd: end.y,
                thickness: originalSegment.thickness,
                color: originalSegment.color,
                sketchLineId: sketchLineId
            });
        }

        simplifiedLineSegments.push(simplifiedSegments);
    }
    );

    return simplifiedLineSegments;
}

function convertLineSegmentsToPoints(lineSegments: LineSegment[][]): Point2D[][] {
    const pointsArray: Point2D[][] = [];

    for (const segmentGroup of lineSegments) {
        const points: Point2D[] = [];

        // Add the start point of the first segment in the group
        if (segmentGroup.length > 0) {
            const firstSegment = segmentGroup[0];
            points.push({ x: firstSegment.xStart, y: firstSegment.yStart });
        }

        // Add the end points of each segment in the group
        for (const segment of segmentGroup) {
            points.push({ x: segment.xEnd, y: segment.yEnd });
        }

        pointsArray.push(points);
    }

    return pointsArray;
}


export const linearizeSketches = (lineSegments: LineSegment[]): Point2D[][] => {
    const epsilon = 0.1;
    const peuckerizedSegments:Point2D[][] = convertLineSegmentsToPoints(simplifyLineSegments(lineSegments, epsilon));
    return peuckerizedSegments;
}

export default Sketch;