import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from './AppContext';
import ShapeFilterEditorWrapper from './ShapeFilterEditorWrapper';
import cursorImage from './sketch_cursor.png';

import "./ShapeLibrary.css";

import { SketchRef } from './Sketch';
import { throwIfNull } from './utils';

type ShapeLibraryProps = {
    setDialogOpen: (value: React.SetStateAction<boolean>) => void;
    activeSketchRef: React.RefObject<SketchRef>;
    queryShapes: (index: number) => void;
}

const ShapeLibrary: React.FC<ShapeLibraryProps> = ({ setDialogOpen, activeSketchRef, queryShapes }) => {
    const { addShapeFilterToLibrary, currentShapeIndex, removeShapeFilterFromLibrary, setCurrentShapeIndex, shapeFilterLibrary, enableEditing, disableEditing} = throwIfNull(useContext(AppContext));

    const [existingShapes, setExistingShapes] = useState<JSX.Element[]>([]); 

    const selectedClassName = "shape-selected";
    const cleanUpAlreadySelectedClasses = () => {
        const shapes = document.getElementsByClassName("shape-item");
        for (var i = 0; i < shapes.length; i++) {
            if (shapes[i].classList.contains(selectedClassName)) {
                shapes[i].classList.remove(selectedClassName);
                break;
            }
        }
    }

    const handleNewShape = () => {
        disableEditing();
        const newIndex = addShapeFilterToLibrary();
        cleanUpAlreadySelectedClasses();
        document.getElementById(`shape-${newIndex}`)?.classList.add(selectedClassName);
        setCurrentShapeIndex(newIndex);
        activeSketchRef.current?.clearCanvas();
        setDialogOpen(true);
    }

    const handleEditShape = () => {
        enableEditing();
        setDialogOpen(true);
    }

    const handleDeleteShape = () => {
        const beforeLength = shapeFilterLibrary.length;
        const shouldUpdateIndex = currentShapeIndex === beforeLength - 1;
        removeShapeFilterFromLibrary(currentShapeIndex);
        let newShapeIndex = currentShapeIndex;
        if (shouldUpdateIndex) {
            newShapeIndex = beforeLength - 2;
            setCurrentShapeIndex(newShapeIndex);
        }
        queryShapes(newShapeIndex);
        //queryShape(shapeFilterLibrary[newShapeIndex]);
    }

    const handleSelection = (shapeIndex: number) => {
        cleanUpAlreadySelectedClasses();
        document.getElementById(`shape-${shapeIndex}`)?.classList.add(selectedClassName);
        setCurrentShapeIndex(shapeIndex);
        queryShapes(shapeIndex);
        //queryShape(shapeFilterLibrary[shapeIndex]);
    }

    const createShapesLibraryItem = (keyPrefix: string, element: JSX.Element) => {
        return (
            <div className="shape-item-wrapper" key={`${keyPrefix}-wrapper`}>
                {element}
            </div>
        );
    }

    
    useEffect(() => {
        setExistingShapes(shapeFilterLibrary.map((shape, index) => {
            const isSelected = currentShapeIndex === index;
            return createShapesLibraryItem(
                `shape-${index}`,
                <button
                    id={`shape-${index}`}
                    key={`shape-${index}`}
                    className={`shape-item ${isSelected ? " " + selectedClassName : ""}`}
                    onClick={() => handleSelection(index)}>
                    <ShapeFilterEditorWrapper
                        sketchRef={currentShapeIndex === index ? activeSketchRef : undefined}  /* only want to clear clear the library item that is associated with the current shown dialog */
                        shapeFilterIndex={index}
                        isViewOnly={true}
                        canvasWidth={300}
                        canvasHeight={100} />
                </button>
            )
        }));
    }, [shapeFilterLibrary, currentShapeIndex]);

    return (
        <div className="shape-library">
            <div style={{ textAlign: 'center' }}>
                <button
                    className="search-button"
                    onClick={handleNewShape}
                    style={{
                        backgroundColor: '#066AFE',
                        color: '#ffffff',
                        border: 'none',
                        padding: '5px 40px',
                        width: '170px',
                        borderRadius: '20px',
                        fontSize: '16px',
                        fontWeight: '700',
                        marginBottom: '20px'
                    }}
                >
                    <img
                        src={cursorImage}
                        alt="Search Icon"
                        style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '4px',
                            verticalAlign: 'middle'  // Align the icon with the middle of the text
                        }}
                    />
                    Search
                </button>
                <div className="shape-list" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <>
                        {existingShapes}
                    </>
                </div>
            </div>
            {shapeFilterLibrary.length > 0 &&
                <div className="shape-library-buttons-wrapper">
                    <button className="shape-button light-button" onClick={handleEditShape}>Edit</button>
                    <button className="shape-button light-button" onClick={handleDeleteShape}>Delete</button>
                </div>
            }
        </div>


    );
};

export default ShapeLibrary;

