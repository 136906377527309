export const sampleMeasureSegments = [
    {
        "xStart": 0.006349206349206349,
        "yStart": -0.023809523809523808,
        "xEnd": 0.006349206349206349,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.006349206349206349,
        "yStart": -0.023809523809523808,
        "xEnd": 0.007936507936507936,
        "yEnd": -0.023809523809523808,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.007936507936507936,
        "yStart": -0.023809523809523808,
        "xEnd": 0.007936507936507936,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.007936507936507936,
        "yStart": -0.020833333333333332,
        "xEnd": 0.007936507936507936,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.007936507936507936,
        "yStart": -0.020833333333333332,
        "xEnd": 0.009523809523809525,
        "yEnd": -0.020833333333333332,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.009523809523809525,
        "yStart": -0.020833333333333332,
        "xEnd": 0.009523809523809525,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.009523809523809525,
        "yStart": -0.017857142857142856,
        "xEnd": 0.011111111111111112,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.011111111111111112,
        "yStart": -0.017857142857142856,
        "xEnd": 0.012698412698412698,
        "yEnd": -0.017857142857142856,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.012698412698412698,
        "yStart": -0.017857142857142856,
        "xEnd": 0.014285714285714285,
        "yEnd": -0.01488095238095238,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.014285714285714285,
        "yStart": -0.01488095238095238,
        "xEnd": 0.015873015873015872,
        "yEnd": -0.011904761904761904,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.015873015873015872,
        "yStart": -0.011904761904761904,
        "xEnd": 0.020634920634920634,
        "yEnd": -0.005952380952380952,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.020634920634920634,
        "yStart": -0.005952380952380952,
        "xEnd": 0.023809523809523808,
        "yEnd": -0.005952380952380952,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.023809523809523808,
        "yStart": -0.005952380952380952,
        "xEnd": 0.02857142857142857,
        "yEnd": 0,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.02857142857142857,
        "yStart": 0,
        "xEnd": 0.031746031746031744,
        "yEnd": 0.002976190476190476,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.031746031746031744,
        "yStart": 0.002976190476190476,
        "xEnd": 0.06031746031746032,
        "yEnd": 0.02976190476190476,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.06031746031746032,
        "yStart": 0.02976190476190476,
        "xEnd": 0.06666666666666667,
        "yEnd": 0.03273809523809524,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.06666666666666667,
        "yStart": 0.03273809523809524,
        "xEnd": 0.0746031746031746,
        "yEnd": 0.03869047619047619,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.0746031746031746,
        "yStart": 0.03869047619047619,
        "xEnd": 0.08888888888888889,
        "yEnd": 0.050595238095238096,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.08888888888888889,
        "yStart": 0.050595238095238096,
        "xEnd": 0.09682539682539683,
        "yEnd": 0.05654761904761905,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.09682539682539683,
        "yStart": 0.05654761904761905,
        "xEnd": 0.10952380952380952,
        "yEnd": 0.06845238095238096,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.10952380952380952,
        "yStart": 0.06845238095238096,
        "xEnd": 0.11746031746031746,
        "yEnd": 0.0744047619047619,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.11746031746031746,
        "yStart": 0.0744047619047619,
        "xEnd": 0.12222222222222222,
        "yEnd": 0.08035714285714286,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.12222222222222222,
        "yStart": 0.08035714285714286,
        "xEnd": 0.12857142857142856,
        "yEnd": 0.08035714285714286,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.12857142857142856,
        "yStart": 0.08035714285714286,
        "xEnd": 0.1365079365079365,
        "yEnd": 0.08928571428571429,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.1365079365079365,
        "yStart": 0.08928571428571429,
        "xEnd": 0.14126984126984127,
        "yEnd": 0.09226190476190477,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.14126984126984127,
        "yStart": 0.09226190476190477,
        "xEnd": 0.14761904761904762,
        "yEnd": 0.09821428571428571,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.14761904761904762,
        "yStart": 0.09821428571428571,
        "xEnd": 0.15079365079365079,
        "yEnd": 0.10119047619047619,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.15079365079365079,
        "yStart": 0.10119047619047619,
        "xEnd": 0.15873015873015872,
        "yEnd": 0.10714285714285714,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.15873015873015872,
        "yStart": 0.10714285714285714,
        "xEnd": 0.16507936507936508,
        "yEnd": 0.1130952380952381,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.16507936507936508,
        "yStart": 0.1130952380952381,
        "xEnd": 0.1761904761904762,
        "yEnd": 0.12202380952380952,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.1761904761904762,
        "yStart": 0.12202380952380952,
        "xEnd": 0.18253968253968253,
        "yEnd": 0.12797619047619047,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.18253968253968253,
        "yStart": 0.12797619047619047,
        "xEnd": 0.19206349206349208,
        "yEnd": 0.13690476190476192,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.19206349206349208,
        "yStart": 0.13690476190476192,
        "xEnd": 0.2,
        "yEnd": 0.14285714285714285,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.2,
        "yStart": 0.14285714285714285,
        "xEnd": 0.20476190476190476,
        "yEnd": 0.14583333333333334,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.20476190476190476,
        "yStart": 0.14583333333333334,
        "xEnd": 0.2126984126984127,
        "yEnd": 0.15476190476190477,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.2126984126984127,
        "yStart": 0.15476190476190477,
        "xEnd": 0.21746031746031746,
        "yEnd": 0.16071428571428573,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.21746031746031746,
        "yStart": 0.16071428571428573,
        "xEnd": 0.2253968253968254,
        "yEnd": 0.16964285714285715,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.2253968253968254,
        "yStart": 0.16964285714285715,
        "xEnd": 0.23333333333333334,
        "yEnd": 0.17857142857142858,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.23333333333333334,
        "yStart": 0.17857142857142858,
        "xEnd": 0.23809523809523808,
        "yEnd": 0.18452380952380953,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.23809523809523808,
        "yStart": 0.18452380952380953,
        "xEnd": 0.24285714285714285,
        "yEnd": 0.19047619047619047,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.24285714285714285,
        "yStart": 0.19047619047619047,
        "xEnd": 0.2523809523809524,
        "yEnd": 0.19940476190476192,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.2523809523809524,
        "yStart": 0.19940476190476192,
        "xEnd": 0.25873015873015875,
        "yEnd": 0.20833333333333334,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.25873015873015875,
        "yStart": 0.20833333333333334,
        "xEnd": 0.2650793650793651,
        "yEnd": 0.21726190476190477,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.2650793650793651,
        "yStart": 0.21726190476190477,
        "xEnd": 0.2714285714285714,
        "yEnd": 0.22321428571428573,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.2714285714285714,
        "yStart": 0.22321428571428573,
        "xEnd": 0.2761904761904762,
        "yEnd": 0.2261904761904762,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.2761904761904762,
        "yStart": 0.2261904761904762,
        "xEnd": 0.27936507936507937,
        "yEnd": 0.23214285714285715,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.27936507936507937,
        "yStart": 0.23214285714285715,
        "xEnd": 0.2841269841269841,
        "yEnd": 0.23809523809523808,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.2841269841269841,
        "yStart": 0.23809523809523808,
        "xEnd": 0.28888888888888886,
        "yEnd": 0.24404761904761904,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.28888888888888886,
        "yStart": 0.24404761904761904,
        "xEnd": 0.29365079365079366,
        "yEnd": 0.25,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.29365079365079366,
        "yStart": 0.25,
        "xEnd": 0.2984126984126984,
        "yEnd": 0.25297619047619047,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.2984126984126984,
        "yStart": 0.25297619047619047,
        "xEnd": 0.3047619047619048,
        "yEnd": 0.25892857142857145,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.3047619047619048,
        "yStart": 0.25892857142857145,
        "xEnd": 0.30634920634920637,
        "yEnd": 0.2619047619047619,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.30634920634920637,
        "yStart": 0.2619047619047619,
        "xEnd": 0.3111111111111111,
        "yEnd": 0.26785714285714285,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.3111111111111111,
        "yStart": 0.26785714285714285,
        "xEnd": 0.319047619047619,
        "yEnd": 0.27380952380952384,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.319047619047619,
        "yStart": 0.27380952380952384,
        "xEnd": 0.32063492063492066,
        "yEnd": 0.27380952380952384,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.32063492063492066,
        "yStart": 0.27380952380952384,
        "xEnd": 0.3253968253968254,
        "yEnd": 0.27976190476190477,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.3253968253968254,
        "yStart": 0.27976190476190477,
        "xEnd": 0.32857142857142857,
        "yEnd": 0.28273809523809523,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.32857142857142857,
        "yStart": 0.28273809523809523,
        "xEnd": 0.3333333333333333,
        "yEnd": 0.2857142857142857,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.3333333333333333,
        "yStart": 0.2857142857142857,
        "xEnd": 0.3380952380952381,
        "yEnd": 0.28869047619047616,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.3380952380952381,
        "yStart": 0.28869047619047616,
        "xEnd": 0.34285714285714286,
        "yEnd": 0.2976190476190476,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.34285714285714286,
        "yStart": 0.2976190476190476,
        "xEnd": 0.36666666666666664,
        "yEnd": 0.31845238095238093,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.36666666666666664,
        "yStart": 0.31845238095238093,
        "xEnd": 0.36984126984126986,
        "yEnd": 0.32142857142857145,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.36984126984126986,
        "yStart": 0.32142857142857145,
        "xEnd": 0.3761904761904762,
        "yEnd": 0.3273809523809524,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.3761904761904762,
        "yStart": 0.3273809523809524,
        "xEnd": 0.3825396825396825,
        "yEnd": 0.3333333333333333,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.3825396825396825,
        "yStart": 0.3333333333333333,
        "xEnd": 0.3873015873015873,
        "yEnd": 0.33630952380952384,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.3873015873015873,
        "yStart": 0.33630952380952384,
        "xEnd": 0.39206349206349206,
        "yEnd": 0.34226190476190477,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.39206349206349206,
        "yStart": 0.34226190476190477,
        "xEnd": 0.3968253968253968,
        "yEnd": 0.3482142857142857,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.3968253968253968,
        "yStart": 0.3482142857142857,
        "xEnd": 0.4015873015873016,
        "yEnd": 0.35119047619047616,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.4015873015873016,
        "yStart": 0.35119047619047616,
        "xEnd": 0.40476190476190477,
        "yEnd": 0.35714285714285715,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.40476190476190477,
        "yStart": 0.35714285714285715,
        "xEnd": 0.40793650793650793,
        "yEnd": 0.3630952380952381,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.40793650793650793,
        "yStart": 0.3630952380952381,
        "xEnd": 0.4158730158730159,
        "yEnd": 0.37202380952380953,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.4158730158730159,
        "yStart": 0.37202380952380953,
        "xEnd": 0.42063492063492064,
        "yEnd": 0.375,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.42063492063492064,
        "yStart": 0.375,
        "xEnd": 0.4222222222222222,
        "yEnd": 0.375,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.4222222222222222,
        "yStart": 0.375,
        "xEnd": 0.4301587301587302,
        "yEnd": 0.38392857142857145,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.4301587301587302,
        "yStart": 0.38392857142857145,
        "xEnd": 0.4365079365079365,
        "yEnd": 0.3898809523809524,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.4365079365079365,
        "yStart": 0.3898809523809524,
        "xEnd": 0.4396825396825397,
        "yEnd": 0.39285714285714285,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.4396825396825397,
        "yStart": 0.39285714285714285,
        "xEnd": 0.4492063492063492,
        "yEnd": 0.4017857142857143,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.4492063492063492,
        "yStart": 0.4017857142857143,
        "xEnd": 0.4587301587301587,
        "yEnd": 0.4107142857142857,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.4587301587301587,
        "yStart": 0.4107142857142857,
        "xEnd": 0.46190476190476193,
        "yEnd": 0.41369047619047616,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.46190476190476193,
        "yStart": 0.41369047619047616,
        "xEnd": 0.46825396825396826,
        "yEnd": 0.4226190476190476,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.46825396825396826,
        "yStart": 0.4226190476190476,
        "xEnd": 0.473015873015873,
        "yEnd": 0.42857142857142855,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.473015873015873,
        "yStart": 0.42857142857142855,
        "xEnd": 0.4777777777777778,
        "yEnd": 0.43452380952380953,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.4777777777777778,
        "yStart": 0.43452380952380953,
        "xEnd": 0.4793650793650794,
        "yEnd": 0.43452380952380953,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.4793650793650794,
        "yStart": 0.43452380952380953,
        "xEnd": 0.4873015873015873,
        "yEnd": 0.44345238095238093,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.4873015873015873,
        "yStart": 0.44345238095238093,
        "xEnd": 0.49206349206349204,
        "yEnd": 0.44642857142857145,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.49206349206349204,
        "yStart": 0.44642857142857145,
        "xEnd": 0.5015873015873016,
        "yEnd": 0.4583333333333333,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.5015873015873016,
        "yStart": 0.4583333333333333,
        "xEnd": 0.5047619047619047,
        "yEnd": 0.46130952380952384,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.5047619047619047,
        "yStart": 0.46130952380952384,
        "xEnd": 0.5126984126984127,
        "yEnd": 0.47023809523809523,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.5126984126984127,
        "yStart": 0.47023809523809523,
        "xEnd": 0.5190476190476191,
        "yEnd": 0.47619047619047616,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.5190476190476191,
        "yStart": 0.47619047619047616,
        "xEnd": 0.5238095238095238,
        "yEnd": 0.4791666666666667,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.5238095238095238,
        "yStart": 0.4791666666666667,
        "xEnd": 0.526984126984127,
        "yEnd": 0.4851190476190476,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.526984126984127,
        "yStart": 0.4851190476190476,
        "xEnd": 0.5285714285714286,
        "yEnd": 0.4880952380952381,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.5285714285714286,
        "yStart": 0.4880952380952381,
        "xEnd": 0.5317460317460317,
        "yEnd": 0.49107142857142855,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.5317460317460317,
        "yStart": 0.49107142857142855,
        "xEnd": 0.5333333333333333,
        "yEnd": 0.49107142857142855,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.5333333333333333,
        "yStart": 0.49107142857142855,
        "xEnd": 0.5349206349206349,
        "yEnd": 0.49107142857142855,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.5349206349206349,
        "yStart": 0.49107142857142855,
        "xEnd": 0.5412698412698412,
        "yEnd": 0.5,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.5412698412698412,
        "yStart": 0.5,
        "xEnd": 0.5412698412698412,
        "yEnd": 0.5,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.5412698412698412,
        "yStart": 0.5,
        "xEnd": 0.5476190476190477,
        "yEnd": 0.5059523809523809,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.5476190476190477,
        "yStart": 0.5059523809523809,
        "xEnd": 0.5523809523809524,
        "yEnd": 0.5119047619047619,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.5523809523809524,
        "yStart": 0.5119047619047619,
        "xEnd": 0.5555555555555556,
        "yEnd": 0.5148809523809523,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.5555555555555556,
        "yStart": 0.5148809523809523,
        "xEnd": 0.5603174603174603,
        "yEnd": 0.5208333333333334,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.5603174603174603,
        "yStart": 0.5208333333333334,
        "xEnd": 0.5650793650793651,
        "yEnd": 0.5238095238095238,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.5650793650793651,
        "yStart": 0.5238095238095238,
        "xEnd": 0.5698412698412698,
        "yEnd": 0.5297619047619048,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.5698412698412698,
        "yStart": 0.5297619047619048,
        "xEnd": 0.5714285714285714,
        "yEnd": 0.5327380952380952,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.5714285714285714,
        "yStart": 0.5327380952380952,
        "xEnd": 0.6126984126984127,
        "yEnd": 0.5803571428571429,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6126984126984127,
        "yStart": 0.5803571428571429,
        "xEnd": 0.6174603174603175,
        "yEnd": 0.5863095238095238,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6174603174603175,
        "yStart": 0.5863095238095238,
        "xEnd": 0.6190476190476191,
        "yEnd": 0.5892857142857143,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6190476190476191,
        "yStart": 0.5892857142857143,
        "xEnd": 0.6238095238095238,
        "yEnd": 0.5952380952380952,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6238095238095238,
        "yStart": 0.5952380952380952,
        "xEnd": 0.6301587301587301,
        "yEnd": 0.6041666666666666,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6301587301587301,
        "yStart": 0.6041666666666666,
        "xEnd": 0.6333333333333333,
        "yEnd": 0.6101190476190477,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6333333333333333,
        "yStart": 0.6101190476190477,
        "xEnd": 0.638095238095238,
        "yEnd": 0.6130952380952381,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.638095238095238,
        "yStart": 0.6130952380952381,
        "xEnd": 0.6428571428571429,
        "yEnd": 0.6190476190476191,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6428571428571429,
        "yStart": 0.6190476190476191,
        "xEnd": 0.6492063492063492,
        "yEnd": 0.6279761904761905,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6492063492063492,
        "yStart": 0.6279761904761905,
        "xEnd": 0.653968253968254,
        "yEnd": 0.6339285714285714,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.653968253968254,
        "yStart": 0.6339285714285714,
        "xEnd": 0.6603174603174603,
        "yEnd": 0.6398809523809523,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6603174603174603,
        "yStart": 0.6398809523809523,
        "xEnd": 0.6619047619047619,
        "yEnd": 0.6428571428571429,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6619047619047619,
        "yStart": 0.6428571428571429,
        "xEnd": 0.6682539682539682,
        "yEnd": 0.6488095238095238,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6682539682539682,
        "yStart": 0.6488095238095238,
        "xEnd": 0.6698412698412698,
        "yEnd": 0.6517857142857143,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6698412698412698,
        "yStart": 0.6517857142857143,
        "xEnd": 0.6730158730158731,
        "yEnd": 0.6577380952380952,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6730158730158731,
        "yStart": 0.6577380952380952,
        "xEnd": 0.6746031746031746,
        "yEnd": 0.6577380952380952,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6746031746031746,
        "yStart": 0.6577380952380952,
        "xEnd": 0.6793650793650794,
        "yEnd": 0.6636904761904762,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6793650793650794,
        "yStart": 0.6636904761904762,
        "xEnd": 0.6825396825396826,
        "yEnd": 0.6666666666666666,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6825396825396826,
        "yStart": 0.6666666666666666,
        "xEnd": 0.6841269841269841,
        "yEnd": 0.6666666666666666,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6841269841269841,
        "yStart": 0.6666666666666666,
        "xEnd": 0.6841269841269841,
        "yEnd": 0.6696428571428571,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6841269841269841,
        "yStart": 0.6696428571428571,
        "xEnd": 0.692063492063492,
        "yEnd": 0.6755952380952381,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.692063492063492,
        "yStart": 0.6755952380952381,
        "xEnd": 0.6968253968253968,
        "yEnd": 0.6815476190476191,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.6968253968253968,
        "yStart": 0.6815476190476191,
        "xEnd": 0.7015873015873015,
        "yEnd": 0.6845238095238095,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7015873015873015,
        "yStart": 0.6845238095238095,
        "xEnd": 0.7047619047619048,
        "yEnd": 0.6904761904761905,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7047619047619048,
        "yStart": 0.6904761904761905,
        "xEnd": 0.707936507936508,
        "yEnd": 0.6964285714285714,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.707936507936508,
        "yStart": 0.6964285714285714,
        "xEnd": 0.7126984126984127,
        "yEnd": 0.7023809523809523,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7126984126984127,
        "yStart": 0.7023809523809523,
        "xEnd": 0.7142857142857143,
        "yEnd": 0.7023809523809523,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7142857142857143,
        "yStart": 0.7023809523809523,
        "xEnd": 0.7222222222222222,
        "yEnd": 0.7113095238095238,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7222222222222222,
        "yStart": 0.7113095238095238,
        "xEnd": 0.7285714285714285,
        "yEnd": 0.7202380952380952,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7285714285714285,
        "yStart": 0.7202380952380952,
        "xEnd": 0.7285714285714285,
        "yEnd": 0.7232142857142857,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7285714285714285,
        "yStart": 0.7232142857142857,
        "xEnd": 0.734920634920635,
        "yEnd": 0.7291666666666666,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.734920634920635,
        "yStart": 0.7291666666666666,
        "xEnd": 0.7412698412698413,
        "yEnd": 0.7380952380952381,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7412698412698413,
        "yStart": 0.7380952380952381,
        "xEnd": 0.7476190476190476,
        "yEnd": 0.7440476190476191,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7476190476190476,
        "yStart": 0.7440476190476191,
        "xEnd": 0.753968253968254,
        "yEnd": 0.7529761904761905,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.753968253968254,
        "yStart": 0.7529761904761905,
        "xEnd": 0.7603174603174603,
        "yEnd": 0.7619047619047619,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7603174603174603,
        "yStart": 0.7619047619047619,
        "xEnd": 0.765079365079365,
        "yEnd": 0.7708333333333334,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.765079365079365,
        "yStart": 0.7708333333333334,
        "xEnd": 0.7666666666666667,
        "yEnd": 0.7708333333333334,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7666666666666667,
        "yStart": 0.7708333333333334,
        "xEnd": 0.7682539682539683,
        "yEnd": 0.7738095238095238,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7682539682539683,
        "yStart": 0.7738095238095238,
        "xEnd": 0.7714285714285715,
        "yEnd": 0.7767857142857143,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7714285714285715,
        "yStart": 0.7767857142857143,
        "xEnd": 0.773015873015873,
        "yEnd": 0.7797619047619048,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.773015873015873,
        "yStart": 0.7797619047619048,
        "xEnd": 0.773015873015873,
        "yEnd": 0.7827380952380952,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.773015873015873,
        "yStart": 0.7827380952380952,
        "xEnd": 0.7777777777777778,
        "yEnd": 0.7886904761904762,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7777777777777778,
        "yStart": 0.7886904761904762,
        "xEnd": 0.7793650793650794,
        "yEnd": 0.7886904761904762,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7793650793650794,
        "yStart": 0.7886904761904762,
        "xEnd": 0.7825396825396825,
        "yEnd": 0.7916666666666666,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7825396825396825,
        "yStart": 0.7916666666666666,
        "xEnd": 0.7873015873015873,
        "yEnd": 0.7976190476190477,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7873015873015873,
        "yStart": 0.7976190476190477,
        "xEnd": 0.7888888888888889,
        "yEnd": 0.8005952380952381,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7888888888888889,
        "yStart": 0.8005952380952381,
        "xEnd": 0.7904761904761904,
        "yEnd": 0.8035714285714286,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7904761904761904,
        "yStart": 0.8035714285714286,
        "xEnd": 0.7936507936507936,
        "yEnd": 0.8065476190476191,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7936507936507936,
        "yStart": 0.8065476190476191,
        "xEnd": 0.7936507936507936,
        "yEnd": 0.8065476190476191,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7936507936507936,
        "yStart": 0.8065476190476191,
        "xEnd": 0.7968253968253968,
        "yEnd": 0.8125,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.7968253968253968,
        "yStart": 0.8125,
        "xEnd": 0.8015873015873016,
        "yEnd": 0.8214285714285714,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8015873015873016,
        "yStart": 0.8214285714285714,
        "xEnd": 0.8015873015873016,
        "yEnd": 0.8214285714285714,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8015873015873016,
        "yStart": 0.8214285714285714,
        "xEnd": 0.8095238095238095,
        "yEnd": 0.8333333333333334,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8095238095238095,
        "yStart": 0.8333333333333334,
        "xEnd": 0.8126984126984127,
        "yEnd": 0.8363095238095238,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8126984126984127,
        "yStart": 0.8363095238095238,
        "xEnd": 0.8158730158730159,
        "yEnd": 0.8452380952380952,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8158730158730159,
        "yStart": 0.8452380952380952,
        "xEnd": 0.819047619047619,
        "yEnd": 0.8482142857142857,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.819047619047619,
        "yStart": 0.8482142857142857,
        "xEnd": 0.8253968253968254,
        "yEnd": 0.8541666666666666,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8253968253968254,
        "yStart": 0.8541666666666666,
        "xEnd": 0.8253968253968254,
        "yEnd": 0.8571428571428571,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8253968253968254,
        "yStart": 0.8571428571428571,
        "xEnd": 0.8301587301587302,
        "yEnd": 0.8601190476190477,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8301587301587302,
        "yStart": 0.8601190476190477,
        "xEnd": 0.8301587301587302,
        "yEnd": 0.8630952380952381,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8301587301587302,
        "yStart": 0.8630952380952381,
        "xEnd": 0.8333333333333334,
        "yEnd": 0.8690476190476191,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8333333333333334,
        "yStart": 0.8690476190476191,
        "xEnd": 0.834920634920635,
        "yEnd": 0.8690476190476191,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.834920634920635,
        "yStart": 0.8690476190476191,
        "xEnd": 0.8396825396825397,
        "yEnd": 0.8779761904761905,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8396825396825397,
        "yStart": 0.8779761904761905,
        "xEnd": 0.8428571428571429,
        "yEnd": 0.8809523809523809,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8428571428571429,
        "yStart": 0.8809523809523809,
        "xEnd": 0.8476190476190476,
        "yEnd": 0.8898809523809523,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8476190476190476,
        "yStart": 0.8898809523809523,
        "xEnd": 0.8507936507936508,
        "yEnd": 0.8958333333333334,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8507936507936508,
        "yStart": 0.8958333333333334,
        "xEnd": 0.8555555555555555,
        "yEnd": 0.9017857142857143,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8555555555555555,
        "yStart": 0.9017857142857143,
        "xEnd": 0.8555555555555555,
        "yEnd": 0.9017857142857143,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8555555555555555,
        "yStart": 0.9017857142857143,
        "xEnd": 0.8603174603174604,
        "yEnd": 0.9077380952380952,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8603174603174604,
        "yStart": 0.9077380952380952,
        "xEnd": 0.861904761904762,
        "yEnd": 0.9107142857142857,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.861904761904762,
        "yStart": 0.9107142857142857,
        "xEnd": 0.8650793650793651,
        "yEnd": 0.9166666666666666,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8650793650793651,
        "yStart": 0.9166666666666666,
        "xEnd": 0.8666666666666667,
        "yEnd": 0.9196428571428571,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8666666666666667,
        "yStart": 0.9196428571428571,
        "xEnd": 0.8682539682539683,
        "yEnd": 0.9226190476190477,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8682539682539683,
        "yStart": 0.9226190476190477,
        "xEnd": 0.873015873015873,
        "yEnd": 0.9285714285714286,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.873015873015873,
        "yStart": 0.9285714285714286,
        "xEnd": 0.8761904761904762,
        "yEnd": 0.9345238095238095,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8761904761904762,
        "yStart": 0.9345238095238095,
        "xEnd": 0.8777777777777778,
        "yEnd": 0.9345238095238095,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8777777777777778,
        "yStart": 0.9345238095238095,
        "xEnd": 0.8809523809523809,
        "yEnd": 0.9404761904761905,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8809523809523809,
        "yStart": 0.9404761904761905,
        "xEnd": 0.8841269841269841,
        "yEnd": 0.9434523809523809,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8841269841269841,
        "yStart": 0.9434523809523809,
        "xEnd": 0.8873015873015873,
        "yEnd": 0.9464285714285714,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8873015873015873,
        "yStart": 0.9464285714285714,
        "xEnd": 0.8873015873015873,
        "yEnd": 0.9464285714285714,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8873015873015873,
        "yStart": 0.9464285714285714,
        "xEnd": 0.8920634920634921,
        "yEnd": 0.9523809523809523,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8920634920634921,
        "yStart": 0.9523809523809523,
        "xEnd": 0.8968253968253969,
        "yEnd": 0.9553571428571429,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8968253968253969,
        "yStart": 0.9553571428571429,
        "xEnd": 0.8984126984126984,
        "yEnd": 0.9583333333333334,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.8984126984126984,
        "yStart": 0.9583333333333334,
        "xEnd": 0.9,
        "yEnd": 0.9583333333333334,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9,
        "yStart": 0.9583333333333334,
        "xEnd": 0.9031746031746032,
        "yEnd": 0.9642857142857143,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9031746031746032,
        "yStart": 0.9642857142857143,
        "xEnd": 0.9047619047619048,
        "yEnd": 0.9642857142857143,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9047619047619048,
        "yStart": 0.9642857142857143,
        "xEnd": 0.9063492063492063,
        "yEnd": 0.9672619047619048,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9063492063492063,
        "yStart": 0.9672619047619048,
        "xEnd": 0.9063492063492063,
        "yEnd": 0.9672619047619048,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9063492063492063,
        "yStart": 0.9672619047619048,
        "xEnd": 0.9079365079365079,
        "yEnd": 0.9672619047619048,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9079365079365079,
        "yStart": 0.9672619047619048,
        "xEnd": 0.9079365079365079,
        "yEnd": 0.9702380952380952,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9079365079365079,
        "yStart": 0.9702380952380952,
        "xEnd": 0.9095238095238095,
        "yEnd": 0.9702380952380952,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9095238095238095,
        "yStart": 0.9702380952380952,
        "xEnd": 0.9095238095238095,
        "yEnd": 0.9702380952380952,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9095238095238095,
        "yStart": 0.9702380952380952,
        "xEnd": 0.9206349206349206,
        "yEnd": 0.9821428571428571,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9206349206349206,
        "yStart": 0.9821428571428571,
        "xEnd": 0.9238095238095239,
        "yEnd": 0.9880952380952381,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9238095238095239,
        "yStart": 0.9880952380952381,
        "xEnd": 0.926984126984127,
        "yEnd": 0.9910714285714286,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.926984126984127,
        "yStart": 0.9910714285714286,
        "xEnd": 0.9301587301587302,
        "yEnd": 0.9940476190476191,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9301587301587302,
        "yStart": 0.9940476190476191,
        "xEnd": 0.9317460317460318,
        "yEnd": 0.9970238095238095,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9317460317460318,
        "yStart": 0.9970238095238095,
        "xEnd": 0.9333333333333333,
        "yEnd": 0.9970238095238095,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9333333333333333,
        "yStart": 0.9970238095238095,
        "xEnd": 0.9349206349206349,
        "yEnd": 1,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9349206349206349,
        "yStart": 1,
        "xEnd": 0.9365079365079365,
        "yEnd": 1.0029761904761905,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9365079365079365,
        "yStart": 1.0029761904761905,
        "xEnd": 0.9380952380952381,
        "yEnd": 1.005952380952381,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9380952380952381,
        "yStart": 1.005952380952381,
        "xEnd": 0.9380952380952381,
        "yEnd": 1.005952380952381,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9380952380952381,
        "yStart": 1.005952380952381,
        "xEnd": 0.9396825396825397,
        "yEnd": 1.005952380952381,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9396825396825397,
        "yStart": 1.005952380952381,
        "xEnd": 0.9412698412698413,
        "yEnd": 1.0089285714285714,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9412698412698413,
        "yStart": 1.0089285714285714,
        "xEnd": 0.9412698412698413,
        "yEnd": 1.0089285714285714,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9412698412698413,
        "yStart": 1.0089285714285714,
        "xEnd": 0.9412698412698413,
        "yEnd": 1.0089285714285714,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9412698412698413,
        "yStart": 1.0089285714285714,
        "xEnd": 0.9428571428571428,
        "yEnd": 1.0119047619047619,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9428571428571428,
        "yStart": 1.0119047619047619,
        "xEnd": 0.9428571428571428,
        "yEnd": 1.0119047619047619,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9428571428571428,
        "yStart": 1.0119047619047619,
        "xEnd": 0.9428571428571428,
        "yEnd": 1.0119047619047619,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9428571428571428,
        "yStart": 1.0119047619047619,
        "xEnd": 0.9444444444444444,
        "yEnd": 1.0119047619047619,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9444444444444444,
        "yStart": 1.0119047619047619,
        "xEnd": 0.9444444444444444,
        "yEnd": 1.0119047619047619,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9444444444444444,
        "yStart": 1.0119047619047619,
        "xEnd": 0.9444444444444444,
        "yEnd": 1.0119047619047619,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9444444444444444,
        "yStart": 1.0119047619047619,
        "xEnd": 0.9444444444444444,
        "yEnd": 1.0119047619047619,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9444444444444444,
        "yStart": 1.0119047619047619,
        "xEnd": 0.946031746031746,
        "yEnd": 1.0119047619047619,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.946031746031746,
        "yStart": 1.0119047619047619,
        "xEnd": 0.9476190476190476,
        "yEnd": 1.0148809523809523,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9476190476190476,
        "yStart": 1.0148809523809523,
        "xEnd": 0.9476190476190476,
        "yEnd": 1.0148809523809523,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9476190476190476,
        "yStart": 1.0148809523809523,
        "xEnd": 0.9492063492063492,
        "yEnd": 1.0148809523809523,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9492063492063492,
        "yStart": 1.0148809523809523,
        "xEnd": 0.9492063492063492,
        "yEnd": 1.0178571428571428,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9492063492063492,
        "yStart": 1.0178571428571428,
        "xEnd": 0.9507936507936507,
        "yEnd": 1.0178571428571428,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9507936507936507,
        "yStart": 1.0178571428571428,
        "xEnd": 0.9507936507936507,
        "yEnd": 1.0178571428571428,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    },
    {
        "xStart": 0.9507936507936507,
        "yStart": 1.0178571428571428,
        "xEnd": 0.9507936507936507,
        "yEnd": 1.0178571428571428,
        "thickness": 5,
        "color": "rgb(78,121,167)",
        "sketchLineId": 23
    }
];