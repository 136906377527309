import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { ShapeFilterDefinition } from './ShapeFilterDefinition';
import { ShapeSearchReturnStruct } from './utils';
import {
  DataTable,
} from "@tableau/embedding-api";
import { cloneLibrary, cloneObjectArray, cloneShapes } from './CopyUtils';

export type MeasureType = {measureName: string, parameter: Map<string,string>, color: string};

export type TextBox = {
    x: number,
    y: number,
    text: string,
    isActive: boolean;
}

export type ShapeType = {annotationShapes: Map<string,ShapeFilterDefinition>, measureShapes: Map<string,ShapeFilterDefinition>, textBoxes: TextBox[]};

interface AppContextProps {
  forecastingEnabled: boolean;
  setForecastingEnabled: (value: boolean) => void;

  searchByPercentage: boolean;
  setSearchByPercentage: (value: boolean) => void;
  
  epsilons: number[];
  epsilonIndex: number;
  setEpsilonIndex: (value: number) => void;

  valueRange: {min: number, max: number};
  dateRange: {startDate: Date, currentDate: Date, endDate: Date};

  canvasWidth: number;
  canvasHeight: number;

  sketchBlob: Blob | undefined;
  setSketchBlob: (blob: Blob) => void;

  tableauVizDataTable: DataTable | undefined;
  setTableauVizDataTable: (dataTable: DataTable | undefined) => void;
  
  angleError: number;
  setAngleError: (value: number) => void;
  lineError: number;
  setLineError: (value: number) => void;
  
  shapeSearchResults:Map<string,ShapeSearchReturnStruct[]>;
  setShapeSearchResults:(value: Map<string,ShapeSearchReturnStruct[]>) => void;

  shapeFilterLibrary: ShapeType[];
  addShapeFilterToLibrary: (newSketch?: ShapeType) =>  number; // returns index where it was stored
  removeShapeFilterFromLibrary: (shapeFilterIndex: number) => void;
  updateShapeInLibrary:(updatedShape:ShapeFilterDefinition, index:number, measureName: string) => void;
  updateLibraryForClearButton:(index: number) => void;
  updateAnnotationInLibrary:(updatedShape:ShapeFilterDefinition, index:number, color: string) => void;
  updateTextBoxesInLibrary:(textBoxes:TextBox[], index: number) => void;
  deactivateAllTextBoxesInLibrary:() => void;
  updateSketchInLibrary:(updatedSketch:ShapeType, index: number) => void;

  currentShapeIndex: number;
  setCurrentShapeIndex: (value: number) => void;

  listOfMeasures: MeasureType[];
  defaultMeasure: MeasureType;
  currentMeasure: string;
  currentColor: string;
  setCurrentColor: (value: string) => void;
  updateCurrentMeasure: (value: string) => void;
  eraserEnabled: boolean;
  setEraserEnabled: (value: boolean) => void;
  isEditingExistingSketch: boolean;
  editingSketch: ShapeType;
  enableEditing: () => void;
  disableEditing: () => void;
  
  isAddingText: boolean;
  setIsAddingText: (value: boolean) => void;
}

export const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const kEpsilonValues:number[] =  [0.01, .015, .02, 0.03, 0.05, 0.075, 0.1, 0.15]; //Array(20).fill(0).map((_, i) => Math.pow(i*0.05,4));
  const kDefaultEpsilonIndex:number = Math.floor((kEpsilonValues.length)/2); //indexing into kEpsilonValues
	
	const valueRange= {min:0, max:1}; //{dataTable.GetValueRange(dataMeasure)}

  const canvasWidth = 900;
  const canvasHeight = 480;

  // Magic Numbers, Required for forecast only
	const dateRange = {
    startDate: new Date(2024, 1, 1), //new Date(Math.min(...yearsFromData).toString()),
    currentDate: new Date(2024, 4, 20), //new Date(Math.max(...yearsFromData).toString()),
    endDate: new Date(2024, 5, 2)
  }; //{dataTable.GetDateRange()};

  const [forecastingEnabled, setForecastingEnabled] = useState(false);
  const [searchByPercentage, setSearchByPercentage] = useState(true);
  const [epsilonIndex, setEpsilonIndex] = useState(kDefaultEpsilonIndex);
  const [angleError, setAngleError] = useState(10);
  const [lineError, setLineError] = useState(0.3);
  const [shapeFilterLibrary, setShapeFilterLibrary] = useState<ShapeType[]>([]);
  const [shapeSearchResults, setShapeSearchResults] = useState<Map<string,ShapeSearchReturnStruct[]>>(new Map<string, ShapeSearchReturnStruct[]>());
  const [currentShapeIndex, setCurrentShapeIndex] = useState<number>(0);
  const [eraserEnabled, setEraserEnabled] = useState<boolean>(false);
  const [isEditingExistingSketch, setIsEditingExistingSketch] = useState<boolean>(false);
  const [editingSketch, setEditingSketch] = useState<ShapeType>({measureShapes: new Map(), annotationShapes: new Map(), textBoxes: []});
  const [sketchBlob, setSketchBlob] = useState<Blob|undefined>();
  const [tableauVizDataTable, setTableauVizDataTable] = useState<DataTable|undefined>();
  const [isAddingText, setIsAddingText] = React.useState(false);

  const listOfMeasures: MeasureType[] = [
    { measureName: "count_local_normalized (baby_names_clean_local_wakawaka_normalized_data)",
      parameter: new Map([
                          ["Signals that match sketch", "selected points 2"],
                        ]),
      color: "rgb(78,121,167)"
    }
  ];
  
  const defaultMeasure = listOfMeasures[0];
  const [currentMeasure, setCurrentMeasure] = useState<string>(defaultMeasure.measureName);
  const [currentColor, setCurrentColor] = useState<string>(defaultMeasure.color);

  useEffect(() => {
    if (shapeFilterLibrary.length > 0) {
      let shape = shapeFilterLibrary[0].measureShapes.get(currentMeasure);
      if (shape) {
        shape.angleError = angleError;
        shapeFilterLibrary[0].measureShapes.set(currentMeasure, shape);
      }
    }
  }, [angleError]);

  useEffect(() => {
    if (shapeFilterLibrary.length > 0 && shapeFilterLibrary[0].measureShapes.has(currentMeasure)) {
      let shape = shapeFilterLibrary[0].measureShapes.get(currentMeasure);
      if (shape) {
        shape.lineError = lineError;
        shapeFilterLibrary[0].measureShapes.set(currentMeasure, shape);
      }
    }
  }, [lineError]);

  useEffect(() => {
    updateColorFromMeasure(currentMeasure);
  }, [currentMeasure]);

  function addShapeFilterToLibrary(newSketch?: ShapeType): number {
    if (newSketch){
      setShapeFilterLibrary(
        [{ annotationShapes: cloneShapes(newSketch.annotationShapes), 
            measureShapes: cloneShapes(newSketch.measureShapes),
            textBoxes: cloneObjectArray(newSketch.textBoxes)
          }, ...shapeFilterLibrary]);
    } else {
      const newMeasure = defaultMeasure.measureName;
      updateCurrentMeasure(newMeasure);
      setShapeFilterLibrary(
        [{ annotationShapes: new Map(), measureShapes: new Map(), textBoxes: []},
          ...shapeFilterLibrary
        ]);
    }
    return 0;
  }

  function updateCurrentMeasure(measureName: string): void {
    setCurrentMeasure(measureName);
    updateColorFromMeasure(measureName);
  }

  function updateColorFromMeasure(measureName: string): void {
    const newColor = listOfMeasures.find(measure => measure.measureName === measureName)?.color;
    if (newColor) {
      setCurrentColor(newColor);
    }
  }

  function removeShapeFilterFromLibrary(shapeFilterIndexToRemove: number): void {
    setShapeFilterLibrary(shapeFilterLibrary.filter((_, i) => i !== shapeFilterIndexToRemove));
  }

  function updateShapeInLibrary(updatedShape:ShapeFilterDefinition, index:number, measureName: string): void {
    if (isEditingExistingSketch) {
      if (editingSketch) {
        const newMeasureShapes = new Map(cloneShapes(editingSketch.measureShapes));
        const newAnnotationShapes = new Map(cloneShapes(editingSketch.annotationShapes));
        newMeasureShapes.set(measureName, updatedShape);
        const newTextBoxes = cloneObjectArray(editingSketch.textBoxes);
        setEditingSketch({
          measureShapes: newMeasureShapes, 
          annotationShapes: newAnnotationShapes, 
          textBoxes: newTextBoxes
        });
      }
    } else {
      const newShapeArray = cloneLibrary(shapeFilterLibrary);
      newShapeArray[index].measureShapes.set(measureName, updatedShape);
      setShapeFilterLibrary(newShapeArray);
    }
  }

  function updateLibraryForClearButton(index: number) {
    if (isEditingExistingSketch) {
      setEditingSketch({measureShapes: new Map(), annotationShapes: new Map(), textBoxes: []})
    } else {
      const newShapeArray = cloneLibrary(shapeFilterLibrary);
      newShapeArray[index] = {annotationShapes: new Map(), measureShapes: new Map(), textBoxes: []};
      setShapeFilterLibrary(newShapeArray);
    }
  }

  function updateAnnotationInLibrary(updatedShape:ShapeFilterDefinition, index:number, annotationColor: string): void {
    if (isEditingExistingSketch) {
      if (editingSketch) {
        const newMeasureShapes = new Map(cloneShapes(editingSketch.measureShapes));
        const newAnnotationShapes = new Map(cloneShapes(editingSketch.annotationShapes));
        newAnnotationShapes.set(annotationColor, updatedShape);
        const newTextBoxes = cloneObjectArray(editingSketch.textBoxes);
        setEditingSketch({measureShapes: newMeasureShapes, annotationShapes: newAnnotationShapes, textBoxes: newTextBoxes});
      }
    } else {
      const newShapeArray = cloneLibrary(shapeFilterLibrary);
      newShapeArray[index].annotationShapes.set(annotationColor, updatedShape);
      setShapeFilterLibrary(newShapeArray);
    }
  }

  function updateTextBoxesInLibrary(textBoxes: TextBox[], index: number) {
    if (isEditingExistingSketch) {
      if (editingSketch) {
        const newMeasureShapes = new Map(cloneShapes(editingSketch.measureShapes));
        const newAnnotationShapes = new Map(cloneShapes(editingSketch.annotationShapes));
        const newTextBoxes = cloneObjectArray(textBoxes);
        setEditingSketch({measureShapes: newMeasureShapes, annotationShapes: newAnnotationShapes, textBoxes: newTextBoxes});
      }
    } else {
      const newShapeArray = cloneLibrary(shapeFilterLibrary);
      newShapeArray[index].textBoxes = cloneObjectArray(textBoxes);
      setShapeFilterLibrary(newShapeArray);
    }
  }

  // deactivate any text boxes that are marked as active so typing doesn't update the text
  function deactivateAllTextBoxesInLibrary() {
    if (isEditingExistingSketch) {
      if (editingSketch) {
        const newMeasureShapes = new Map(cloneShapes(editingSketch.measureShapes));
        const newAnnotationShapes = new Map(cloneShapes(editingSketch.annotationShapes));
        let newTextBoxes = getDisabledTextBoxes(editingSketch.textBoxes);
        setEditingSketch({measureShapes: newMeasureShapes, annotationShapes: newAnnotationShapes, textBoxes: newTextBoxes});
      }
    } else {
      const newShapeArray = cloneLibrary(shapeFilterLibrary);
      newShapeArray[currentShapeIndex].textBoxes = getDisabledTextBoxes(newShapeArray[currentShapeIndex].textBoxes);
      setShapeFilterLibrary(newShapeArray);
    }
  }

  function updateSketchInLibrary(updatedShape:ShapeType, index: number) {
    const newShapeArray = cloneLibrary(shapeFilterLibrary);
    newShapeArray[index] = {
      measureShapes: cloneShapes(updatedShape.measureShapes),
      annotationShapes: cloneShapes(updatedShape.annotationShapes), 
      textBoxes: getDisabledTextBoxes(updatedShape.textBoxes)
    };
    setShapeFilterLibrary(newShapeArray);
  }

  function getDisabledTextBoxes(textBoxes: TextBox[]): TextBox[] {
    let newTextBoxes = cloneObjectArray(textBoxes);
    newTextBoxes.forEach((textBox: TextBox) => textBox.isActive = false);
    return newTextBoxes;
  }

  function enableEditing() {
    setIsEditingExistingSketch(true);
    const currentShape = shapeFilterLibrary[currentShapeIndex];
    let newMeasureShapes = cloneShapes(currentShape.measureShapes);
    let newAnnotationShapes = cloneShapes(currentShape.annotationShapes);
    let newTextBoxes = cloneObjectArray(currentShape.textBoxes);
    setEditingSketch({measureShapes: newMeasureShapes, annotationShapes: newAnnotationShapes, textBoxes: newTextBoxes});
  }

  function disableEditing() {
    setIsEditingExistingSketch(false);
    setEditingSketch({measureShapes: new Map(), annotationShapes: new Map(), textBoxes: []});
  }

  return (
    <AppContext.Provider
      value={{
        forecastingEnabled,
        setForecastingEnabled,
        searchByPercentage,
        setSearchByPercentage,
        epsilons:kEpsilonValues,
        epsilonIndex,
        setEpsilonIndex,
        valueRange,
        dateRange,
        canvasWidth,
        canvasHeight,
        sketchBlob,
        setSketchBlob,
        tableauVizDataTable,
        setTableauVizDataTable,
        angleError,
        setAngleError,
        lineError,
        setLineError,
        shapeSearchResults,
        setShapeSearchResults,
        shapeFilterLibrary,
        addShapeFilterToLibrary,
        removeShapeFilterFromLibrary,
        updateShapeInLibrary,
        updateLibraryForClearButton,
        updateAnnotationInLibrary,
        updateTextBoxesInLibrary,
        deactivateAllTextBoxesInLibrary,
        updateSketchInLibrary,
        currentShapeIndex,
        setCurrentShapeIndex,
        listOfMeasures,
        currentMeasure,
        defaultMeasure,
        updateCurrentMeasure,
        currentColor,
        setCurrentColor,
        eraserEnabled,
        setEraserEnabled,
        isEditingExistingSketch,
        editingSketch,
        enableEditing,
        disableEditing,
        isAddingText,
        setIsAddingText
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
