import React, { useContext, useRef } from "react";
import "./Layout.css";
//import ControlPanel from "./ControlPanel";
import CustomDialog from "./CustomDialog";
import ShapeFilterEditorWrapper from "./ShapeFilterEditorWrapper";
import TableauVizComponent from "./TableauVizComponent";
import { AppContext, MeasureType } from "./AppContext";
import ShapeLibrary from "./ShapeLibrary";
import { ReactComponent as TableauLogo } from "../Tableau_From_Salesforce_Logo_Color.svg";
import Chat from "./Chat";
import CustomTabs from "./CustomTabs";
import { linearizeSketches, SketchRef } from "./Sketch";
import { throwIfNull } from "./utils";
import { Dashboard, Workbook } from "@tableau/embedding-api";

import { ReactComponent as EraserIcon } from "../Eraser_Icon.svg";
import { ReactComponent as AddTextIcon } from "../TextString_Icon.svg";
import { ShapeFilterDefinition } from "./ShapeFilterDefinition";

const Layout: React.FC = () => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState(0);
  const sketchRef = useRef<SketchRef>(null);
  const vizRef = useRef<any>(null);
  const context = useContext(AppContext);

  if (!context) {
    return null; // or throw an error
  }

  const {
    currentShapeIndex,
    canvasWidth,
    canvasHeight,
    //queryShape,
    addShapeFilterToLibrary,
    updateLibraryForClearButton,
    updateSketchInLibrary,
    removeShapeFilterFromLibrary,
    shapeFilterLibrary,
    currentMeasure,
    defaultMeasure,
    currentColor,
    setCurrentColor,
    setCurrentShapeIndex,
    updateCurrentMeasure,
    listOfMeasures,
    setEraserEnabled,
    eraserEnabled,
    isEditingExistingSketch,
    editingSketch,
    disableEditing,
    isAddingText,
    setIsAddingText,
    deactivateAllTextBoxesInLibrary,
  } = context;

  const handleClearButton = () => {
    sketchRef.current?.clearCanvas();
    updateLibraryForClearButton(currentShapeIndex);
  };

  const handleCancelButton = () => {
    if (isEditingExistingSketch) {
      disableEditing();
    } else {
      removeShapeFilterFromLibrary(currentShapeIndex);
    }
    setDialogOpen(false);
  };

  const handleClickMeasure = (measure: MeasureType) => {
    if (currentMeasure === measure.measureName && currentColor === measure.color) {
      // if measure is already enabled, disable it
      setCurrentColor("");
    } else {
      // if measure is not enabled, enable it
      setCurrentColor(measure.color);
      setEraserEnabled(false);
      deactivateAllTextBoxesInLibrary();
      updateCurrentMeasure(measure.measureName);
    }
  };

  const handleClickAnnotationColor = (color: string) => {
    if (currentColor === color) {
      // if annotation color is already enabled, disable it
      setCurrentColor("");
    } else {
      // if annotation color is not enabled, enable it
      console.log(`setting current color to ${color}`);
      setEraserEnabled(false);
      deactivateAllTextBoxesInLibrary();
      setCurrentColor(color);
    }
  };

  const handleClickEraser = () => {
    setCurrentColor("");
    deactivateAllTextBoxesInLibrary();
	  setIsAddingText(false);
    setEraserEnabled(!eraserEnabled);
  };

  const handleClickTextButton = () => {
    if (isAddingText) {
      // if already enabled, disable it
      setIsAddingText(false);
    } else {
      deactivateAllTextBoxesInLibrary();
      setCurrentColor("");
      setEraserEnabled(false);
      setIsAddingText(true);
    }
  }

  const handleSaveButton = () => {
    setDialogOpen(false);
    if (isEditingExistingSketch) {
      updateSketchInLibrary(editingSketch, currentShapeIndex);
      disableEditing();
      sketchRef.current?.saveSketchAsPng();
      handleShapeQueries(currentShapeIndex);
    } else {
      sketchRef.current?.saveSketchAsPng();
      handleShapeQueries(currentShapeIndex);
    }
  };

  const handleSaveAsButton = () => {
    setDialogOpen(false);
    if (isEditingExistingSketch) {
      const newIndex = addShapeFilterToLibrary(editingSketch);
      setCurrentShapeIndex(newIndex);
      disableEditing();
      handleShapeQueries(currentShapeIndex);
    } else {
      handleShapeQueries(currentShapeIndex);
    }
  };

  const handleShapeQueries = async (index: number) => {
		const shapeDefinitions = shapeFilterLibrary[index].measureShapes
		shapeDefinitions.forEach((value: ShapeFilterDefinition, key: string) => {
			const linearizedSegments = linearizeSketches(value.GetLineSegments());
			let linearizedSegmentsString = "";
			linearizedSegments.forEach(segment => linearizedSegmentsString += segment.map(value => "{" + value.x + "," + value.y + "}").join(','));
			console.log(linearizedSegmentsString);
			let workbook: Workbook = vizRef.current.workbook;
			const sheet = (workbook.activeSheet as Dashboard).worksheets[0];
			const parameterMap = listOfMeasures?.find(measureObj => measureObj.measureName === key)?.parameter;
			if (parameterMap) {
				const parameter:string = throwIfNull(parameterMap.get(sheet.name))
				console.log(`updating ${parameter} to ${linearizedSegmentsString}`);
				workbook.changeParameterValueAsync(parameter, linearizedSegmentsString);
			}
		});
	};

  let measureButtons: JSX.Element[] = [];
  listOfMeasures.forEach((measure) =>
    measureButtons.push(
      <button
        className="measure-button"
        key={measure.measureName}
        onClick={() => handleClickMeasure(measure)}
      >
        <span className="measure-button-swatch-holder">
          <span
            className={`measure-button-swatch ${
              measure.measureName === defaultMeasure.measureName
                ? "measure-button-swatch-blue"
                : "measure-button-swatch-orange"
            } ${
              currentMeasure === measure.measureName &&
              currentColor === measure.color
                ? "selected"
                : ""
            }`}
          ></span>
        </span>
        <div className="measure-button-text-wrapper">
          <span
            className={`measure-button-text ${
              currentMeasure === measure.measureName &&
              currentColor === measure.color
                ? "selected"
                : ""
            }`}
          >
            {measure.measureName === "Customer CSAT"
              ? "Customer Satisfaction"
              : measure.measureName}
          </span>
        </div>
      </button>
    )
  );

  return (
    <div className="container">
      <div className="header">
        <div className="title-wrapper">
          <TableauLogo />
        </div>
      </div>
      <div className="main-content">
        <div className="viz-wrapper">
          <TableauVizComponent
            url={"https://10ay.online.tableau.com/t/onlinerocks/views/baby_names_heroku_db/Dashboard2"}
						vizRef={vizRef}
          />
        </div>
        <div className="sidebar">
          <CustomTabs
            onClick={(tabNumber: number) => setCurrentTab(tabNumber)}
            currentTab={currentTab}
          />
          {currentTab === 0 && 
            <ShapeLibrary 
              setDialogOpen={setDialogOpen} 
              activeSketchRef={sketchRef}
              queryShapes={(index: number) => {handleShapeQueries(index)}}
            />
          }
          {currentTab === 1 && <Chat />}
        </div>
      </div>
      <CustomDialog
        open={dialogOpen}
        enableSaveAs={isEditingExistingSketch}
        onRequestClear={handleClearButton}
        onRequestCancel={handleCancelButton}
        onRequestSave={handleSaveButton}
        onRequestSaveAs={handleSaveAsButton}
        children={
          <div className="shape-editor-wrapper">
            <div className="shape-editor-options-description">
              Choose a measure color to sketch a shape or an annotation color to
              add notes.
            </div>
            <ShapeFilterEditorWrapper
              sketchRef={sketchRef}
              shapeFilterIndex={currentShapeIndex}
              isViewOnly={false}
              isAddingText={isAddingText}
              canvasWidth={canvasWidth}
              canvasHeight={canvasHeight}
            />
            <div className="shape-editor-options">
              <div className="measure-options">
                <div className="measure-colors-description">
                  <b>Measures</b>
                </div>
                <div className="measure-buttons-wrapper">{measureButtons}</div>
              </div>

              <div className="annotation-options">
                <div className="annotation-colors-description">
                  <b>Annotation Palette</b>
                </div>
                <div className="annotation-colors-wrapper">
                  <button
                    className={`annotation-color annotation-color-black ${
                      currentColor === "#636363" ? "selected" : ""
                    }`}
                    onClick={() => handleClickAnnotationColor("#636363")}
                  ></button>
                  <button
                    className={`annotation-color annotation-color-green ${
                      currentColor === "#669B59" ? "selected" : ""
                    }`}
                    onClick={() => handleClickAnnotationColor("#669B59")}
                  ></button>
                  <button
                    className={`annotation-color annotation-color-purple ${
                      currentColor === "#7a599b" ? "selected" : ""
                    }`}
                    onClick={() => handleClickAnnotationColor("#7a599b")}
                  ></button>
                </div>
              </div>

			        <div className="editor-button-wrapper text-button-wrapper">
                <div className="editor-button-buffer"></div>
                <button className="editor-button" onClick={handleClickTextButton}>
                  <div
                    className={`editor-icon-holder ${
                      isAddingText ? "selected" : ""
                    }`}
                  >
                    <AddTextIcon />
                  </div>
                  <div className="editor-button-text-wrapper">
                    <div
                      className={`editor-button-text ${
                        isAddingText ? "selected" : ""
                      }`}
                    >
                      Text
                    </div>
                  </div>
                </button>
              </div>

              <div className="editor-button-wrapper eraser-button-wrapper">
                <div className="editor-button-buffer"></div>
                <button className="editor-button" onClick={handleClickEraser}>
                  <div
                    className={`editor-icon-holder ${
                      eraserEnabled ? "selected" : ""
                    }`}
                  >
                    <EraserIcon />
                  </div>
                  <div className="editor-button-text-wrapper">
                    <div
                      className={`editor-button-text ${
                        eraserEnabled ? "selected" : ""
                      }`}
                    >
                      Eraser
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Layout;
