import React, { useEffect } from "react";
import {
  TableauEventType,
} from "@tableau/embedding-api";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "tableau-viz": any;
    }
  }
}

type TableauVizComponentProps = {
  url: string;
  vizRef: React.RefObject<any>
};

const TableauVizComponent: React.FC<TableauVizComponentProps> = ({
  url,
  vizRef,
}) => {

  useEffect(() => {
      vizRef.current.addEventListener(
        TableauEventType.VizLoadError,
        (error: any) => {
          console.error("Viz load error");
        }
      );
    },[vizRef]);

  return (
    <div className="tableau-viz-component" id="tableau-viz-wrapper">
      <tableau-viz
        touch-optimize
        ref={vizRef}
        id="tableau-viz"
        src={url}
        width={"100%"}
      />
    </div>
  )
};

export default TableauVizComponent;