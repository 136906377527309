import { ShapeType } from "./AppContext";
import { ShapeFilterDefinition } from "./ShapeFilterDefinition";

export function cloneLibrary(shapeFilterLibrary: ShapeType[]): ShapeType[] {
    return shapeFilterLibrary.map(sketch => {
        return {
            annotationShapes: cloneShapes(sketch.annotationShapes),
            measureShapes: cloneShapes(sketch.measureShapes),
            textBoxes: cloneObjectArray(sketch.textBoxes)
        }
    })
}

export function cloneShapes(
  map: Map<string, ShapeFilterDefinition>
): Map<string, ShapeFilterDefinition> {
  let clone = new Map<string, ShapeFilterDefinition>();
  map.forEach((shape: ShapeFilterDefinition, key: string) => {
    let clonedShape = new ShapeFilterDefinition(25, 0.35);
    clonedShape._lineSegments = cloneObjectArray(shape._lineSegments);
    clone.set(key, clonedShape);
  });
  return clone;
}

export function cloneObjectArray(arr: any[]) {
  return JSON.parse(JSON.stringify(arr));
}
