import React, { useContext } from 'react';
import { AppContext } from './AppContext';
import { Sketch, SketchRef } from './Sketch';

type ShapeFilterEditorWrapperProps = {
  shapeFilterIndex: number;
  isViewOnly: boolean;
  isAddingText?: boolean;
  canvasWidth: number;
  canvasHeight: number;
  sketchRef?: React.RefObject<SketchRef>;
}

const ShapeFilterEditorWrapper: React.FC<ShapeFilterEditorWrapperProps> = ({shapeFilterIndex, isViewOnly, isAddingText, canvasWidth, canvasHeight, sketchRef}) => {
    const context = useContext(AppContext);

    if (!context) {
      return null; // or throw an error
    }

    return (
      <div style={{width: '100%', height: '100%'}}>
        <Sketch 
          ref={sketchRef} 
          shapeFilterIndex={shapeFilterIndex} 
          isViewOnly={isViewOnly} 
          isAddingText={isAddingText}
          canvasWidth={canvasWidth} 
          canvasHeight={canvasHeight}/>
      </div>
    );
};

export default ShapeFilterEditorWrapper;

